<template>
  <div class="pane_container" id="pane_container">
    <el-row :gutter="16" class="row_css" style="border: 2px solid #1a96d4; border-radius: 10px;padding: 5px;">
      <el-col :span="4">
        <div class="box_description" style="margin-bottom: 5px;">
          <img src="@/assets/img/基本信息.png" alt="" class="box_span_img">
          <span class="box_span" style="margin-right: 20px;">基本信息</span>
          <el-tag type="success" v-if="basicInformation.Status == 1"  size="mini">在线</el-tag>
          <el-tag type="info" v-else  size="mini">在装</el-tag>
        </div>
        <div class="box_content">
          <el-row class="box_content_row">
            <el-col :span="24">
              <template v-if="this.basicInformation.DeviceTypeId == 'HS_CKYTHZM'">
                <el-descriptions :column="1" class="left_descriptions">
                  <el-descriptions-item label="安装地点">{{basicInformation.InstalledLocation}}</el-descriptions-item>
                  <el-descriptions-item label="控制模式">{{basicInformation.ControlMode=="1"?"流量":"闸位"}}</el-descriptions-item>
                  <!-- 流量 -->
                  <el-descriptions-item label="目标流量(m³/s)" v-if="basicInformation.ControlMode=='1'">{{basicInformation.FlowControlSettingValue}}</el-descriptions-item>
                  <!-- 闸位 -->
                  <el-descriptions-item label="目标闸位(m)" v-else>{{basicInformation.GateControlSettingValue}}</el-descriptions-item>
                  <el-descriptions-item label="操作人员">{{basicInformation.Operator}}</el-descriptions-item>
                  <!-- 下发渠道 0未知 1本地 2:网页  3：小程序  -->
                  <el-descriptions-item label="下发渠道" v-if="basicInformation.DeliveryChannel=='0'">未知</el-descriptions-item>
                  <el-descriptions-item label="下发渠道" v-if="basicInformation.DeliveryChannel=='1'">本地</el-descriptions-item>
                  <el-descriptions-item label="下发渠道" v-if="basicInformation.DeliveryChannel=='2'">网页</el-descriptions-item>
                  <el-descriptions-item label="下发渠道" v-if="basicInformation.DeliveryChannel=='3'">小程序</el-descriptions-item>
                </el-descriptions>
              </template>
              <template v-else>
                <el-descriptions :column="1" class="left_descriptions">
                  <el-descriptions-item label="设备编号">{{basicInformation.DeviceNo}}</el-descriptions-item>
                  <el-descriptions-item label="软件版本号">{{basicInformation.VersionNo}}</el-descriptions-item>
                  <el-descriptions-item label="安装地点">{{basicInformation.InstalledLocation}}</el-descriptions-item>
                  <el-descriptions-item label="安装时间">{{basicInformation.InstalledTime}}</el-descriptions-item>
                  <el-descriptions-item label="经度坐标">{{basicInformation.Longitude}}</el-descriptions-item>
                  <el-descriptions-item label="纬度坐标">{{basicInformation.Latitude}}</el-descriptions-item>
                  <el-descriptions-item label="用水定额(m³)">{{basicInformation.WaterConsumptionNorm}}</el-descriptions-item>
                  <el-descriptions-item label="灌溉亩数(亩)">{{basicInformation.IrrigatedAcreage}}</el-descriptions-item>
                  <el-descriptions-item label="作物类型">{{basicInformation.CropTypeName}}</el-descriptions-item>
                  <el-descriptions-item label="分支机构">{{basicInformation.BelongAreaName}}</el-descriptions-item>
                  <!-- <el-descriptions-item label="设备安装图片">{{basicInformation.DeviceNo}}</el-descriptions-item> -->
                  <el-descriptions-item label="设备描述">{{basicInformation.DeviceRemark}}</el-descriptions-item>
                </el-descriptions>

              </template>
             
            </el-col>
            <!-- <el-col :span="12">
              <el-descriptions :column="1" class="left_descriptions">
                <template v-if="basicInformation.DeviceTypeId=='HS_CKYTHZM'">
                  <el-descriptions-item label="瞬时流量"> {{ basicInformation.FlowRate }}</el-descriptions-item>
                  <el-descriptions-item label="累计流量"> {{ basicInformation.TotalFlow }}</el-descriptions-item>
                  <el-descriptions-item label="当前闸位"> {{ basicInformation.GatePosition }}</el-descriptions-item>
                  <el-descriptions-item label="流量计水位"> {{ basicInformation.WaterLevel_LLJ }}</el-descriptions-item>
                  <el-descriptions-item label="流量计水深"> {{ basicInformation.WaterDepth }}</el-descriptions-item>
                  <el-descriptions-item label="水位计水位"> {{ basicInformation.WaterLevel_SWJ }}</el-descriptions-item>
                </template>
                <template v-else-if="basicInformation.DeviceTypeId=='HS_SWJ'">
                  <el-descriptions-item label="水位"> {{ basicInformation.WaterLevel_SWJ }}</el-descriptions-item>
                </template>
                <template v-else>
                  <el-descriptions-item label="水位"> {{ basicInformation.WaterLevel_LLJ }}</el-descriptions-item>
                  <el-descriptions-item label="水深"> {{ basicInformation.WaterDepth }}</el-descriptions-item>
                  <el-descriptions-item label="累计流量"> {{ basicInformation.TotalFlow }}</el-descriptions-item>
                  <el-descriptions-item label="流速"> {{ basicInformation.FlowVelocity }}</el-descriptions-item>
                  <el-descriptions-item label="水温"> {{ basicInformation.WaterTemperature }}</el-descriptions-item>
                </template>
                <el-descriptions-item label="是否报警"> {{ basicInformation.AlarmStatus =='1'?'报警':'不报警' }}</el-descriptions-item>
              </el-descriptions>
            </el-col> -->
          </el-row>
          <div class="box_content_bottom">
            <el-button type="success" plain size="mini" @click="refreshData()">刷新</el-button>
            <el-button type="primary" size="mini" @click="goCheck(1)">查看明细</el-button>
            <el-button type="success" size="mini" @click="openConDialog()">配置信息</el-button>
          </div>
        </div>
      </el-col>
      <el-col :span="10">
        <!-- <div class="box_description" style="margin-bottom: 5px;">
          <img src="@/assets/img/动画.png" alt="" class="box_span_img">
          <span class="box_span">设备动画</span>
        </div> -->
        <div class="box_content"> 
          <div :style="{ backgroundImage: 'url(' + backgroundImage + ')' ,}" style="width:100%;background-repeat:no-repeat;background-size:80%;background-position:50% 20px;height: 400px;" >
          <!-- <div :style="{ background: 'url(' + backgroundImage + ')  no-repeat 20% -45%',backgroundSize:'80%',}" class="row_video_css"> -->
            <el-row style="font-size: 14px;color:#606266;">
              <!-- 顶部 -->
              <el-col :span="24"  >
                <template v-if="basicInformation.Icon==1||basicInformation.Icon==2||basicInformation.Icon==3||basicInformation.Icon==4||basicInformation.Icon==5||basicInformation.Icon==6||basicInformation.Icon==7">
                  <!-- 闸门 -->
                  <el-col :span="11" style="border:1px solid transparent"></el-col>
                    <el-col :span="13">
                      <el-col :span="24">
                        当前闸位: <span class="red_font"> {{ basicInformation.GatePosition }} </span>  (m)
                      </el-col>
                    </el-col>
                </template>
                <template v-else>
                  <template v-if="basicInformation.DeviceTypeId == 'HS_SWJ'">
                    <el-col :span="24" style="text-align: center;">
                      水位计水位: <span class="red_font"> {{ basicInformation.WaterLevel_SWJ }} </span> (m)
                    </el-col>
                  </template>
                  <template v-else>
                    <el-col :span="24" style="text-align: center;">
                      流量计水位:  <span class="red_font"> {{ basicInformation.WaterLevel_LLJ }} </span> (m) &nbsp;&nbsp;&nbsp;
                      流量计水深:  <span class="red_font"> {{ basicInformation.WaterDepth }} </span> (m) <br/>
                      瞬时流量:  <span class="red_font"> {{ basicInformation.FlowRate }} </span> (m³/s) &nbsp;&nbsp;&nbsp;
                      累计水量:  <span class="red_font"> {{ basicInformation.TotalFlow }} </span> (m³)
                    </el-col>
                  </template>
                </template>
              </el-col>
              <!-- 下部 -->
              <div style="position: relative;" v-if="basicInformation.Icon==1||basicInformation.Icon==2||basicInformation.Icon==3||basicInformation.Icon==4||basicInformation.Icon==5||basicInformation.Icon==6||basicInformation.Icon==7"> 
                <el-col :span="6" style="position: relative;text-align: right;top:calc((100vh - 45px - 35px - 10px - 50px - 20px) / 2 - 215px);">   
                  <template v-if="basicInformation.Icon==1||basicInformation.Icon==7"> 
                    <!-- 流量计 -->
                    <el-col :span="24">
                      流量计水位:  <span class="red_font"> {{ basicInformation.WaterLevel_LLJ }} </span> (m)
                    </el-col>
                    <el-col :span="24">
                      流量计水深: <span class="red_font">  {{ basicInformation.WaterDepth }} </span> (m)
                    </el-col>
                    <el-col :span="24">
                      瞬时流量: <span class="red_font">  {{ basicInformation.FlowRate }} </span> (m³/s)
                    </el-col>
                    <el-col :span="24">
                      累计水量: <span class="red_font">  {{ basicInformation.TotalFlow }} </span> (m³)
                    </el-col>
                  </template>
                  <template v-else>
                    <!-- 水位计 -->
                    <el-col :span="24">
                      水位计水位: <span class="red_font">  {{ basicInformation.WaterLevel_SWJ }} </span> (m)
                    </el-col>

                  </template>
                </el-col>
                <el-col :span="12" style="border:1px solid transparent" > </el-col>
                <el-col :span="6" style="text-align: left" >
                  <template v-if="basicInformation.Icon!=1&&basicInformation.Icon!=7"> 
                    <!-- 流量计 -->
                    <el-col :span="24">
                      流量计水位: <span class="red_font">  {{ basicInformation.WaterLevel_LLJ }} </span> (m)
                    </el-col>
                    <el-col :span="24">
                      流量计水深: <span class="red_font">  {{ basicInformation.WaterDepth }} </span> (m)
                    </el-col>
                    <el-col :span="24">
                      瞬时流量: <span class="red_font">  {{ basicInformation.FlowRate }} </span> (m³/s)
                    </el-col>
                    <el-col :span="24">
                      累计水量: <span class="red_font">  {{ basicInformation.TotalFlow }} </span> (m³)
                    </el-col>
                  </template>
                  <template v-else>
                    <!-- 水位计 -->
                    <el-col :span="24">
                      水位计水位: <span class="red_font">  {{ basicInformation.WaterLevel_SWJ }} </span> (m)
                    </el-col>
                  </template>
                </el-col>
              </div>
            </el-row>
          </div>
          <!-- <template v-if="basicInformation.Icon">c:\Users\lgx\Desktop\aaa.png
            <el-image style="width: 100%;border: 1px solid red;" :src="require(`@/assets/device_images/${basicInformation.Icon}.jpg`)"></el-image>
          </template>
          <template v-else>
            
          </template> -->
        </div>
      </el-col>
      <el-col :span="10">
        <div class="box_description" style="margin-bottom: 5px;">
          <img src="@/assets/img/监控.png" alt="" class="box_span_img">
          <span class="box_span">设备监控</span>
        </div>
        <div class="box_content">
          <!-- <video :src="videoUrl" controls :autoplay="true" style="width: 100%;margin-bottom: 10px;" class="row_video_css"></video> -->
          <iframe :src="videoUrl" style="width: 100%;margin-bottom: 10px;" class="row_video_css"> </iframe>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="16" class="row_css bottom_row" style="margin-top: 10px;">
      <el-col :span="2">
        <div class="bottom_row_border">
          <!-- 闸门 -->
          <template  v-if="basicInformation.DeviceTypeId=='HS_CKYTHZM'">
            <div class="box_description" style="margin-bottom: 5px;">
              <img src="@/assets/img/急停.png" alt="" class="box_span_img">
              <span class="box_span"> 闸门急停</span>
            </div>
            <div class="box_content" style="padding: 20px;text-align: center;">
              <el-image style="width: 80%;margin: 0px 0;cursor: pointer;" :src="require('@/assets/img/stop.png')" @click="stopGate"></el-image>
            </div>
            <div class="box_description" style="margin-bottom: 5px;">
              <img src="@/assets/img/操作.png" alt="" class="box_span_img">
              <span class="box_span"> 监测站操作 </span>
            </div>
            <div style="text-align: center;">
              <el-button type="warning" size="mini" @click="goJczData()" style="margin-top: 20%;width:90%;white-space: normal;">固态存储数据</el-button>
              <el-button  type="danger" size="mini" @click="clearHistory()" style="margin-top: 20%;margin-left: 0;width:90%;white-space: normal;">清空历史数据</el-button>
            </div>
          </template>
          <!-- 其他设备 -->
          <template v-else>
            <div class="box_description" style="margin-bottom: 5px;">
              <img src="@/assets/img/操作.png" alt="" class="box_span_img">
              <span class="box_span"> 监测站操作 </span>
            </div>
            <div style="text-align: center;margin-top: 20%;">
              <el-image :title="'监测站固态存储数据'" style="width: 40%;margin: 10px auto;cursor: pointer;" :src="require('@/assets/img/jiance.png')" @click="goJczData()"></el-image>
              <p style="font-size: 12px;color: #1890ff;margin: 5px;">固态存储数据</p>
            </div>
            <div style="text-align: center;margin-top: 20%;">
              <el-image :title="'清空监测站历史数据'" style="width: 40%;margin: 10px auto;cursor: pointer;" :src="require('@/assets/img/clear.png')" @click="clearHistory()"></el-image>
              <p style="font-size: 12px;color: #1890ff;margin: 5px;">清空历史数据</p>
            </div>
          </template>
        </div>
      </el-col>
      <!-- 测控一体化闸门 -->
      <template v-if="basicInformation.DeviceTypeId == 'HS_CKYTHZM'">
        <el-col :span="9">
          <div class="bottom_row_border" style="margin: 0 4px;">
            <div class="box_description" style="margin-bottom: 5px;">
              <img src="@/assets/img/控制.png" alt="" class="box_span_img">
              <span class="box_span">闸门控制</span>
            </div>
            <div class="box_content controlForm_box">
              <el-form ref="controlForm" :model="controlForm" :rules="ontrolRules" label-width="130px" class="controlForm">
                <el-form-item label="计划名称" prop="PlanName">
                  <el-input v-model="controlForm.PlanName"></el-input>
                </el-form-item>
                <el-form-item label="控制模式" prop="ControlMode">
                  <el-select v-model="controlForm.ControlMode" placeholder="控制模式" style="width: 100%;">
                    <el-option label="流量" value="1"></el-option>
                    <el-option label="闸位" value="2"></el-option>
                  </el-select>
                </el-form-item>
                <!-- 流量 -->
                <el-form-item v-if="controlForm.ControlMode=='1'" :label="'控制参数(m³/s)'" prop="controlParam">
                  <el-input-number  size="mini" v-model="controlForm.controlParam" style="width: 100%;" :min="-999999.999" :max="999999.999"  class="special_i"></el-input-number>
                </el-form-item>
                <!-- 闸位 -->
                <el-form-item v-if="controlForm.ControlMode=='2'"  :label="'控制参数(m)'" prop="controlParam">
                  <el-input-number  size="mini" v-model="controlForm.controlParam" style="width: 100%;" :min="0" :max="99.999" class="special_i"></el-input-number>
                </el-form-item>
                <el-form-item label="立即执行">
                  <el-switch v-model="controlForm.ExecuteImmediately" @change="chExecuteImmediately"></el-switch>
                </el-form-item>
                <el-form-item label="开始时间" prop="executeStartTime" v-if="!controlForm.ExecuteImmediately">
                  <el-date-picker type="datetime" placeholder="选择开始时间" v-model="controlForm.executeStartTime" style="width: 100%;" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
                </el-form-item>
                <el-form-item label="结束时间" prop="executeEndTime" v-if="!controlForm.ExecuteImmediately">
                  <el-date-picker type="datetime" placeholder="选择结束时间" v-model="controlForm.executeEndTime" style="width: 100%;" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
                </el-form-item>
                <!-- <el-form-item label="执行时间" v-if="!controlForm.ExecuteImmediately">
                  <el-col :span="11">
                    <el-date-picker type="datetime" placeholder="选择开始时间" v-model="controlForm.executeStartTime" style="width: 100%;"></el-date-picker>
                  </el-col>
                  <el-col class="line" :span="2" style="text-align: center;">-</el-col>
                  <el-col :span="11">
                    <el-date-picker type="datetime" placeholder="选择结束时间" v-model="controlForm.executeEndTime" style="width: 100%;"></el-date-picker>
                  </el-col>
                </el-form-item> -->
                <el-form-item label="开闸密码" prop="openGatePwd">
                  <el-input v-model="controlForm.openGatePwd"  :maxlength="4" @input="handleInputPwds"></el-input>
                </el-form-item>
              </el-form>
            </div>
            <div style="text-align:right">
              <el-button type="warning" size="mini" @click="clearControlForm('controlForm')">清空</el-button>
              <el-button size="mini" type="primary" @click="onControlSubmit('controlForm')">提交</el-button>
            </div>
          </div>
        </el-col>
        <el-col :span="13">
          <div class="bottom_row_border" id="table_box_height" style="z-index: 999;">
            <div class="box_description" style="margin-bottom: 5px;">
              <img src="@/assets/img/计划.png" alt="" class="box_span_img">
              <span class="box_span">计划控制列表</span> <span class="box_span" style="display: inline-block;position: absolute;right:20px;top:5px;cursor: pointer;" @click="getList" >刷新</span>
            </div>
            <div class="box_content" id="table_box">
              <!-- <basic-table :tableHeight="tableHeightPlan" :tableHeaderList="firstHeaderListPlan" :tableData="tableDataPlan" :listQuery="listQueryPlan" @pagination="handleCurrentChange" :tableTotal="tableTotalPlan" :otherColumn="otherColumn" :hasIndex="true"> -->
              <basic-table :tableHeight="tableHeightPlan" :tableHeaderList="firstHeaderListPlan" :tableData="tableDataPlan" :listQuery="listQueryPlan" @pagination="handleCurrentChange" :tableTotal="tableTotalPlan" :hasIndex="true">
                <!-- <template v-slot:operate="row" >
                  <el-button  v-if="row.rowData.status==0" size="mini" type="danger" @click="deletePlan(row.rowData)">删除  </el-button>
                </template> -->
              </basic-table>
            </div>
          </div>
        </el-col>
      </template>
      <!-- 流量计、水位计 -->
      <template v-else>
        <el-col :span="11">
          <div class="bottom_row_border" id="echarts_box_height" style="margin: 0 4px;">
            <div class="box_description" style="margin-bottom: 5px;">
              <img src="@/assets/img/曲线图.png" alt="" class="box_span_img">
              <span class="box_span">实时水位曲线图</span>
            </div>
            <div id="RealTimeWaterLevel" :style="{width: '100%', height: chartHeight + 'px',}"></div>
          </div>
        </el-col>
        <el-col :span="11">
          <div class="bottom_row_border">
            <div class="box_description" style="margin-bottom: 5px;">
              <img src="@/assets/img/曲线图.png" alt="" class="box_span_img">
              <span class="box_span">实时流量曲线图</span>
            </div>
            <div id="RealTimeTraffic" :style="{width: '100%', height: chartHeight + 'px',}"></div>
          </div>
        </el-col>
      </template>

    </el-row>

    <!-- 明细弹窗 -->
    <el-dialog title="查看明细" :visible.sync="detailsDialog" width="70%" class="showDetails">
      <!-- {{ basicInformation }} -->
      <div>
        <el-divider content-position="left"><span style="color: #1a96d4;">实时数据</span></el-divider>
        <el-descriptions :column="3" class="left_descriptions">
          <template v-if="basicInformation.DeviceTypeId=='HS_CKYTHZM'">
            <el-descriptions-item label="流量计水位 (m)"> {{ basicInformation.WaterLevel_LLJ }}</el-descriptions-item>
            <el-descriptions-item label="流量计水深 (m)"> {{ basicInformation.WaterDepth }}</el-descriptions-item>
            <el-descriptions-item label="水位计水位 (m)"> {{ basicInformation.WaterLevel_SWJ }}</el-descriptions-item>
            <el-descriptions-item label="瞬时流量(m³/s)"> {{ basicInformation.FlowRate }}</el-descriptions-item>
            <el-descriptions-item label="累计流量(m³)"> {{ basicInformation.TotalFlow }}</el-descriptions-item>
            <el-descriptions-item label="流速(m/s)"> {{basicInformation.FlowVelocity }}</el-descriptions-item>
            <el-descriptions-item label="闸位(m)"> {{ basicInformation.GatePosition }}</el-descriptions-item>
            <el-descriptions-item label="水温"> {{ basicInformation.WaterTemperature }}</el-descriptions-item>
          </template>
          <template v-else-if="basicInformation.DeviceTypeId=='HS_SWJ'">
            <el-descriptions-item label="水位 (m)"> {{ basicInformation.WaterLevel_SWJ }}</el-descriptions-item>
          </template>
          <template v-else>
            <el-descriptions-item label="水位 (m)"> {{ basicInformation.WaterLevel_LLJ }}</el-descriptions-item>
            <el-descriptions-item label="水深 (m)"> {{ basicInformation.WaterDepth }}</el-descriptions-item>
            <el-descriptions-item label="瞬时流量(m³/s)"> {{ basicInformation.FlowRate }}</el-descriptions-item>
            <el-descriptions-item label="累计流量(m³)"> {{ basicInformation.TotalFlow }}</el-descriptions-item>
            <el-descriptions-item label="流速(m/s)"> {{ basicInformation.FlowVelocity }}</el-descriptions-item>
            <el-descriptions-item label="水温"> {{ basicInformation.WaterTemperature }}</el-descriptions-item>
          </template>
          <el-descriptions-item label="是否报警"> {{ basicInformation.AlarmStatus =='1'?'报警':'不报警' }}</el-descriptions-item>
          <template v-if="basicInformation.DeviceTypeId == 'HS_CKYTHZM'">
            <el-descriptions-item label="闸位控制设定值">{{basicInformation.GateControlSettingValue}}</el-descriptions-item>
            <el-descriptions-item label="流量控制设定值">{{basicInformation.FlowControlSettingValue}}</el-descriptions-item> 
            <el-descriptions-item label="电机数据A电压">{{basicInformation.MotorDataA_V}}</el-descriptions-item>
            <el-descriptions-item label="电机数据B电压">{{basicInformation.MotorDataB_V}}</el-descriptions-item>
            <el-descriptions-item label="电机数据C电压">{{basicInformation.MotorDataC_V}}</el-descriptions-item>
            <el-descriptions-item label="电机数据A电流">{{basicInformation.MotorDataA_A}}</el-descriptions-item>
            <el-descriptions-item label="电机数据B电流">{{basicInformation.MotorDataB_A}}</el-descriptions-item>
            <el-descriptions-item label="电机数据C电流">{{basicInformation.MotorDataC_A}}</el-descriptions-item>

          </template>
          <template v-else-if="basicInformation.DeviceTypeId == 'HS_SWLLJ'">
            <el-descriptions-item label="流量-水位1">{{basicInformation.FlowlevelCorrespondence1}}</el-descriptions-item>
            <el-descriptions-item label="流量-水位2">{{basicInformation.FlowlevelCorrespondence2}}</el-descriptions-item>
            <el-descriptions-item label="流量-水位3">{{basicInformation.FlowlevelCorrespondence3}}</el-descriptions-item>
            <el-descriptions-item label="流量-水位4">{{basicInformation.FlowlevelCorrespondence4}}</el-descriptions-item>
            <el-descriptions-item label="流量-水位5">{{basicInformation.FlowlevelCorrespondence5}}</el-descriptions-item>

          </template>
          <template v-else-if="(basicInformation.DeviceTypeId == 'HS_XSCSBMQLLJ') || (basicInformation.DeviceTypeId == 'HS_KFSCSBMQLLJ') || (basicInformation.DeviceTypeId == 'HS_WXSCSBMQLLJ')">
            <el-descriptions-item label="水位传感器1状态">{{getAlarmStatus(basicInformation.WaterLevelGaugesStatus_1)}}</el-descriptions-item>
            <el-descriptions-item label="水位传感器2状态">{{getAlarmStatus(basicInformation.WaterLevelGaugesStatus_2)}}</el-descriptions-item>
            <el-descriptions-item label="水位传感器3状态">{{getAlarmStatus(basicInformation.WaterLevelGaugesStatus_3)}}</el-descriptions-item>
            <el-descriptions-item label="水位传感器4状态">{{getAlarmStatus(basicInformation.WaterLevelGaugesStatus_4)}}</el-descriptions-item>
            <el-descriptions-item label="换能器1状态">{{ getAlarmStatus(basicInformation.TransducersStatus_1)}}</el-descriptions-item>
            <el-descriptions-item label="换能器2状态">{{ getAlarmStatus(basicInformation.TransducersStatus_2)}}</el-descriptions-item>
            <el-descriptions-item label="换能器3状态">{{ getAlarmStatus(basicInformation.TransducersStatus_3)}}</el-descriptions-item>
            <el-descriptions-item label="换能器4状态">{{ getAlarmStatus(basicInformation.TransducersStatus_4)}}</el-descriptions-item>
            <el-descriptions-item label="换能器5状态">{{ getAlarmStatus(basicInformation.TransducersStatus_5)}}</el-descriptions-item>
            <el-descriptions-item label="换能器6状态">{{ getAlarmStatus(basicInformation.TransducersStatus_6)}}</el-descriptions-item>
            <el-descriptions-item label="换能器7状态">{{ getAlarmStatus(basicInformation.TransducersStatus_7)}}</el-descriptions-item>
            <el-descriptions-item label="换能器8状态">{{ getAlarmStatus(basicInformation.TransducersStatus_8)}}</el-descriptions-item>
            <el-descriptions-item label="换能器9状态">{{ getAlarmStatus(basicInformation.TransducersStatus_9)}}</el-descriptions-item>
            <el-descriptions-item label="换能器10状态">{{ getAlarmStatus(basicInformation.TransducersStatus_10)}}</el-descriptions-item>
            <el-descriptions-item label="换能器11状态">{{ getAlarmStatus(basicInformation.TransducersStatus_11)}}</el-descriptions-item>
            <el-descriptions-item label="换能器12状态">{{ getAlarmStatus(basicInformation.TransducersStatus_12)}}</el-descriptions-item>
            <el-descriptions-item label="换能器13状态">{{ getAlarmStatus(basicInformation.TransducersStatus_13)}}</el-descriptions-item>
            <el-descriptions-item label="换能器14状态">{{ getAlarmStatus(basicInformation.TransducersStatus_14)}}</el-descriptions-item>
            <el-descriptions-item label="换能器15状态">{{ getAlarmStatus(basicInformation.TransducersStatus_15)}}</el-descriptions-item>
            <el-descriptions-item label="换能器16状态">{{ getAlarmStatus(basicInformation.TransducersStatus_16)}}</el-descriptions-item>
            <el-descriptions-item label="换能器17状态">{{ getAlarmStatus(basicInformation.TransducersStatus_17)}}</el-descriptions-item>
            <el-descriptions-item label="换能器18状态">{{ getAlarmStatus(basicInformation.TransducersStatus_18)}}</el-descriptions-item>
            <el-descriptions-item label="换能器19状态">{{ getAlarmStatus(basicInformation.TransducersStatus_19)}}</el-descriptions-item>
            <el-descriptions-item label="换能器20状态">{{ getAlarmStatus(basicInformation.TransducersStatus_20)}}</el-descriptions-item>
            <el-descriptions-item label="换能器21状态">{{ getAlarmStatus(basicInformation.TransducersStatus_21)}}</el-descriptions-item>
            <el-descriptions-item label="换能器22状态">{{ getAlarmStatus(basicInformation.TransducersStatus_22)}}</el-descriptions-item>
            <el-descriptions-item label="换能器23状态">{{ getAlarmStatus(basicInformation.TransducersStatus_23)}}</el-descriptions-item>
            <el-descriptions-item label="换能器24状态">{{ getAlarmStatus(basicInformation.TransducersStatus_24)}}</el-descriptions-item>
            <el-descriptions-item label="换能器25状态">{{ getAlarmStatus(basicInformation.TransducersStatus_25)}}</el-descriptions-item>
            <el-descriptions-item label="换能器26状态">{{ getAlarmStatus(basicInformation.TransducersStatus_26)}}</el-descriptions-item>
            <el-descriptions-item label="换能器27状态">{{ getAlarmStatus(basicInformation.TransducersStatus_27)}}</el-descriptions-item>
            <el-descriptions-item label="换能器28状态">{{ getAlarmStatus(basicInformation.TransducersStatus_28)}}</el-descriptions-item>
            <el-descriptions-item label="换能器29状态">{{ getAlarmStatus(basicInformation.TransducersStatus_29)}}</el-descriptions-item>
            <el-descriptions-item label="换能器30状态">{{ getAlarmStatus(basicInformation.TransducersStatus_30)}}</el-descriptions-item>
            <el-descriptions-item label="换能器31状态">{{ getAlarmStatus(basicInformation.TransducersStatus_31)}}</el-descriptions-item>
            <el-descriptions-item label="换能器32状态">{{ getAlarmStatus(basicInformation.TransducersStatus_32)}}</el-descriptions-item>
          </template>
        </el-descriptions>
        <el-divider content-position="left"><span style="color: #1a96d4;">基本信息</span></el-divider>
        <el-descriptions :column="3" class="left_descriptions">
          <el-descriptions-item label="设备编号">{{basicInformation.DeviceNo}}</el-descriptions-item>
          <el-descriptions-item label="软件版本号">{{basicInformation.VersionNo}}</el-descriptions-item>
          <el-descriptions-item label="安装地点">{{basicInformation.InstalledLocation}}</el-descriptions-item>
          <el-descriptions-item label="安装时间">{{basicInformation.InstalledTime}}</el-descriptions-item>
          <el-descriptions-item label="经度坐标">{{basicInformation.Longitude}}</el-descriptions-item>
          <el-descriptions-item label="纬度坐标">{{basicInformation.Latitude}}</el-descriptions-item>
          <el-descriptions-item label="用水定额(m³)">{{basicInformation.WaterConsumptionNorm}}</el-descriptions-item>
          <el-descriptions-item label="灌溉亩数(亩)">{{basicInformation.IrrigatedAcreage}}</el-descriptions-item>
          <el-descriptions-item label="作物类型">{{basicInformation.CropTypeName}}</el-descriptions-item>
          <el-descriptions-item label="分支机构">{{basicInformation.BelongAreaName}}</el-descriptions-item>
          <el-descriptions-item label="设备安装图片">{{basicInformation.DeviceNo}}</el-descriptions-item>
          <el-descriptions-item label="设备描述">{{basicInformation.DeviceRemark}}</el-descriptions-item>
        </el-descriptions>

        <el-divider content-position="left"><span style="color: #1a96d4;">个性化信息</span></el-divider>
        <el-descriptions :column="3" class="left_descriptions">
          <!-- 测控一体化闸门 -->
          <template v-if="basicInformation.DeviceTypeId == 'HS_CKYTHZM'">
            <el-descriptions-item label="闸板宽(mm)">{{basicInformation.GateWidth}}</el-descriptions-item>
            <el-descriptions-item label="闸板高(mm)">{{basicInformation.GateHeight}}</el-descriptions-item>
            <el-descriptions-item label="闸板最大行程(mm)">{{basicInformation.GateMaximumStroke}}</el-descriptions-item>
            <el-descriptions-item label="闸前设备"> {{dictionary('HS_Device_GateFrontEquipment',basicInformation.GateFrontEquipmentId)}}</el-descriptions-item>
            <el-descriptions-item label="闸后设备">{{dictionary('HS_Device_GateBackEquipment',basicInformation.GateBackEquipmentId)}}</el-descriptions-item>
            <el-descriptions-item label="供电方式">{{dictionary('HS_Device_PowerSupplyMode',basicInformation.PowerSupplyMode)}}</el-descriptions-item>
            <el-descriptions-item label="电机电压(V)">{{basicInformation.MotorVoltage}}</el-descriptions-item>
            <el-descriptions-item label="电机功率(W)">{{basicInformation.MotorPower}}</el-descriptions-item>
          </template>
          <!-- 箱式超声波明渠流量计 -->
          <template v-if="basicInformation.DeviceTypeId == 'HS_XSCSBMQLLJ'">
            <el-descriptions-item label="测箱宽h(mm)">{{basicInformation.MeasuringBoxWidth}}</el-descriptions-item>
            <el-descriptions-item label="测箱高w(mm)">{{basicInformation.MeasuringBoxHeight}}</el-descriptions-item>
            <el-descriptions-item label="换能器数量(n)">{{basicInformation.TransducerCount}}</el-descriptions-item>
            <el-descriptions-item label="换能器频率"> {{dictionary('HS_Device_TransducerFrequency',basicInformation.TransducerFrequency)}}</el-descriptions-item>
            <el-descriptions-item label="供电方式">{{dictionary('HS_Device_PowerSupplyMode',basicInformation.PowerSupplyMode)}}</el-descriptions-item>
            <el-descriptions-item label="水位传感器安装方式">{{dictionary('HS_Device_WaterLevelSensorInstalledMethod',basicInformation.WaterLevelSensorInstalledMethod)}}</el-descriptions-item>
            <el-descriptions-item label="水位传感器数量">{{basicInformation.WaterLevelSensorCount}}</el-descriptions-item>

          </template>
          <!-- 开放式超声波明渠流量计 -->
          <template v-if="basicInformation.DeviceTypeId == 'HS_KFSCSBMQLLJ'">
            <el-descriptions-item label="渠道顶宽(mm)">{{basicInformation.ChannelTopWidth}}</el-descriptions-item>
            <el-descriptions-item label="渠道底宽(mm)">{{basicInformation.ChannelBottomWidth}}</el-descriptions-item>
            <el-descriptions-item label="渠道深度(mm)">{{basicInformation.ChannelDeep}}</el-descriptions-item>
            <el-descriptions-item label="声道数"> {{basicInformation.ChannelCount}}</el-descriptions-item>
            <el-descriptions-item label="供电方式">{{dictionary('HS_Device_PowerSupplyMode',basicInformation.PowerSupplyMode)}}</el-descriptions-item>

          </template>
          <!-- 无线式超声波明渠流量计 -->
          <template v-if="basicInformation.DeviceTypeId == 'HS_WXSCSBMQLLJ'">
            <el-descriptions-item label="渠道顶宽(mm)">{{basicInformation.ChannelTopWidth}}</el-descriptions-item>
            <el-descriptions-item label="渠道底宽(mm)">{{basicInformation.ChannelBottomWidth}}</el-descriptions-item>
            <el-descriptions-item label="渠道深度(mm)">{{basicInformation.ChannelDeep}}</el-descriptions-item>
            <el-descriptions-item label="声道数"> {{basicInformation.ChannelCount}}</el-descriptions-item>
            <el-descriptions-item label="供电方式">{{dictionary('HS_Device_PowerSupplyMode',basicInformation.PowerSupplyMode)}}</el-descriptions-item>

          </template>
          <!-- 巴歇尔槽明渠流量计 -->
          <template v-if="basicInformation.DeviceTypeId == 'HS_BXECMQLLJ'">
            <el-descriptions-item label="槽号">{{basicInformation.Slot}}</el-descriptions-item>
            <el-descriptions-item label="B值(mm)">{{basicInformation.BValue}}</el-descriptions-item>
          </template>

          <!-- 水位流量计 -->
          <template v-if="basicInformation.DeviceTypeId == 'HS_SWLLJ'">

          </template>
          <!-- 水位计 -->
          <template v-if="basicInformation.DeviceTypeId == 'HS_SWJ'">

          </template>
        </el-descriptions>
        <!-- <el-divider content-position="left"><span style="color: #1a96d4;">配置信息</span></el-divider> -->
        <el-descriptions :column="3" class="left_descriptions">
          <!-- <el-descriptions-item label="监测站密码">{{basicInformation.MonitoringDtationPassword}}</el-descriptions-item>
          <el-descriptions-item label="水位基值">{{basicInformation.WaterLevelBaseline}}</el-descriptions-item>
          <el-descriptions-item label="初始水量">{{basicInformation.InitialWaterVolume}}</el-descriptions-item>
          <el-descriptions-item label="上报间隔时间">{{basicInformation.ReportingIntervalTime}}</el-descriptions-item>
          <el-descriptions-item label="主备信道中心站地址">
            <el-tooltip placement="top-start">
              <div slot="content">{{basicInformation.CentralAddress}}</div>
              <span>{{basicInformation.CentralAddress}}</span>
            </el-tooltip>
          </el-descriptions-item>
          <el-descriptions-item label="流量上限">{{basicInformation.FlowLimit}}</el-descriptions-item>
          <el-descriptions-item label="流量系数">{{basicInformation.FlowCoefficient}}</el-descriptions-item>
          <el-descriptions-item label="切除流速">{{basicInformation.CutFlowSpeed}}</el-descriptions-item>
          <el-descriptions-item label="水位基值上下限">
            <el-tooltip placement="top-start">
              <div slot="content">{{basicInformation.WaterLevelBaselines}}</div>
              <span> {{basicInformation.WaterLevelBaselines}}</span>
            </el-tooltip>
           
          </el-descriptions-item> -->
          

        </el-descriptions>
        

      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="detailsDialog = false" size="mini">确定</el-button>
      </span>
    </el-dialog>
    <!-- 配置弹窗 -->
    <el-dialog title="配置信息" :visible.sync="configurationDialog" width="70%" :key="dialogType" v-loading="comLoading">
      <el-row style="display: flex; flex-wrap: wrap; justify-content: left">
        <el-col :span="12">
          <div style="display: flex;margin-bottom:15px ;">
            <div style="width: 23%;line-height: 28px"> 原密码 ：</div>
            <div style="width: 50%;">
              <el-input v-model="originalPassword" type="text" size="mini"  :maxlength="4" @input="handleInputPwd"></el-input>
            </div>
          </div>
        </el-col>
        <el-col :span="12" style="display: flex;margin-bottom:15px ;">
          <div style="width: 23%;line-height: 28px"> 在线状态 ：</div>
          <el-tag type="success" v-if="basicInformation.Status == 1"  size="mini" style="margin-top: 5px;">在线</el-tag>
          <el-tag type="info" v-else  size="mini" style="margin-top: 5px;">在装</el-tag>
        </el-col>
        <template v-for="(item,index) in configuration">
          <template v-if="item.cmd==101">
            <el-divider  :key="index"  content-position="left"><span style="color: #1a96d4;">基值上下限</span></el-divider>
                <template v-for="(ita1,ind1) in item.value" >
                  <template v-if="rowData.deviceTypeId == 'HS_CKYTHZM'">  
                    <template v-for="(ita2,ind2) in ita1" >
                      <el-col :span="8" >
                        <div style="display: flex;margin-bottom:15px ;">
                        <div style="width: 23%;line-height: 28px"> {{ ita2.label }} ：</div>
                        <div style="width: 50%;">
                            <template v-if="ind1==0">
                              <el-input-number size="mini" v-model="ita2.value" style="width: 100%;" :min="-7999.99" :max="7999.99"></el-input-number>
                            </template>
                            <template v-else>
                              <el-input-number size="mini" v-model="ita2.value" style="width: 100%;" :min="0" :max="99.99"></el-input-number>
                            </template>
                            <el-input-number size="mini" v-model="ita2.valueText" style="width: 100%;margin-top: 5px;"  disabled></el-input-number>
                        </div>
                        <div style="width: 10%;margin-left:10px" > <el-button v-if="item.write==1&&ind1==2&&ind2==2" size="mini" type="success" @click="writeCommand(item)">写入</el-button></div>
                      </div>
                    </el-col>
                    </template>
                  </template>
                  <template v-else-if="rowData.deviceTypeId == 'HS_SWJ'">  
                    <template v-for="(ita2,ind2) in ita1" >
                      <el-col :span="12" >
                        <div style="display: flex;margin-bottom:15px ;">
                        <div style="width: 23%;line-height: 28px"> {{ ita2.label }} ：</div>
                        <div style="width: 50%;">
                          <template v-if="ind1==0">
                              <el-input-number size="mini" v-model="ita2.value" style="width: 100%;" :min="-7999.99" :max="7999.99"></el-input-number>
                            </template>
                            <template v-else>
                              <el-input-number size="mini" v-model="ita2.value" style="width: 100%;" :min="0" :max="99.99"></el-input-number>
                            </template>
                            <el-input-number size="mini" v-model="ita2.valueText" style="width: 100%;margin-top: 5px;"  disabled></el-input-number>
                        </div>
                        <div style="width: 10%;margin-left:10px"> <el-button v-if="item.write==1&&ind1==2&&ind2==0" size="mini" type="success" @click="writeCommand(item)">写入</el-button></div>
                      </div>
                    </el-col>
                    <el-col :span="1" style="border:1px solid transparent;"></el-col>
                    </template>

                  </template>
                  <template v-else>  
                    <template v-for="(ita2,ind2) in ita1" >
                      <el-col :span="12" >
                        <div style="display: flex;margin-bottom:15px ;">
                        <div style="width: 23%;line-height: 28px"> {{ ita2.label }} ：</div>
                        <div style="width: 50%;">
                          <template v-if="ind1==0">
                              <el-input-number size="mini" v-model="ita2.value" style="width: 100%;" :min="-7999.99" :max="7999.99"></el-input-number>
                            </template>
                            <template v-else>
                              <el-input-number size="mini" v-model="ita2.value" style="width: 100%;" :min="0" :max="99.99"></el-input-number>
                            </template>
                            <el-input-number size="mini" v-model="ita2.valueText" style="width: 100%;margin-top: 5px;"  disabled></el-input-number>
                        </div>
                        <div style="width: 10%;margin-left:10px"  > <el-button v-if="item.write==1&&ind1==2&&ind2==1" size="mini" type="success" @click="writeCommand(item)">写入</el-button></div>
                      </div>
                    </el-col>
                    </template>
                  </template>
                  <!-- <el-col :span="12"  >
                      <div style="display: flex;margin-bottom:15px ;">
                      <div style="width: 23%;line-height: 28px"> {{ ita1.label }} ：</div>
                      <div style="width: 50%;">
                          <el-input-number size="mini" v-model="ita1.value" style="width: 100%;" :min="-7999.99" :max="7999.99"></el-input-number>
                      </div>

                      <div style="width: 10%;margin-left:10px" v-if="rowData.deviceTypeId == 'HS_CKYTHZM'"> <el-button v-if="item.write==1&&ind1==8" size="mini" type="success" @click="writeCommand(item)">写入</el-button></div>
                      <div style="width: 10%;margin-left:10px" v-else-if="rowData.deviceTypeId == 'HS_SWJ'"> <el-button v-if="item.write==1&&ind1==2" size="mini" type="success" @click="writeCommand(item)">写入</el-button></div>
                      <div style="width: 10%;margin-left:10px" v-else> <el-button v-if="item.write==1&&ind1==5" size="mini" type="success" @click="writeCommand(item)">写入</el-button></div>
                    </div>
                </el-col> -->
              </template>
          </template>
          <template v-else-if="item.cmd==162">
            <!-- <el-row :key="index" > -->
              <el-divider :key="index" content-position="left"><span style="color: #1a96d4;">主备信道中心站地址</span></el-divider>
                  <template v-for="(ita2,ind2) in item.value" >
                    <el-col :span="12" >
                      <div style="display: flex;margin-bottom:15px ;" >
                      <div style="width: 23%;line-height: 28px"> {{ ita2.label }}：</div>
                      <div style="width: 50%;">
                          <el-input size="mini" v-model="ita2.value" ></el-input>
                          <el-input size="mini" v-model="ita2.valueText" disabled style="margin-top: 5px;"></el-input>
                      </div>
                      <!-- <div style="width: 10%;margin-left:10px"> <el-button v-if="item.read==1" size="mini" type="primary" @click="readCommand(item)">读取</el-button> </div> -->
                      <div style="width: 10%;margin-left:10px"> <el-button v-if="item.write==1&&ind2==1" size="mini" type="success" @click="writeCommand(item)">写入</el-button></div>
                    </div>
                  </el-col>
                </template>
            <!-- </el-row> -->
          </template>
          <template v-else-if="item.cmd==165">
            <!-- <el-row :key="index" > -->
              <el-divider :key="index" content-position="left"><span style="color: #1a96d4;">水位流量关系</span></el-divider>
                  <template v-for="(ita3,ind3) in item.value" >
                    <el-col :span="12" >
                      <div style="display: flex;margin-bottom:15px ;" >
                      <div style="width: 23%;line-height: 28px"> {{ ita3.label }}：</div>
                      <div style="width: 50%;">
                        <el-input-number size="mini" v-model="ita3.value" style="width: 100%;" :min="0" :max="999.999"></el-input-number>
                        <el-input-number size="mini" v-model="ita3.valueText" style="width: 100%;margin-top: 5px;"  disabled></el-input-number>
                          <!-- <el-input size="mini" v-model="ita.value"></el-input> -->
                      </div>
                      <!-- <div style="width: 10%;margin-left:10px"> <el-button v-if="item.read==1" size="mini" type="primary" @click="readCommand(item)">读取</el-button> </div> -->
                      <div style="width: 10%;margin-left:10px"> <el-button v-if="item.write==1&&ind3==9" size="mini" type="success" @click="writeCommand(item)">写入</el-button></div>
                    </div>
                  </el-col>
                </template>
            <!-- </el-row> -->
          </template>
          <template  v-else>
            <el-col :span="12" :key="index">
            <div style="display: flex;margin-bottom:15px ;">
              <div style="width: 23%;line-height: 28px"> 
                {{ item.label }} ：<div v-if="item.cmd==127" style="margin-top:5px"> 累计流量 ： </div>
              </div>
              <div style="width: 50%;">
                <template v-if="item.cmd==150">   
                  <el-input v-model="item.value" type="text"  size="mini"  :maxlength="4" @input="handleInputPwds"></el-input>
                  <el-input v-model="item.valueText" type="text"  size="mini" disabled style="width: 100%;margin-top: 5px;"  ></el-input>
                </template >
                <template v-else-if="item.cmd==127">   
                  <el-input-number size="mini" v-model="item.value" style="width: 100%;" :min="0" :max="7999999999"></el-input-number>
                  <el-input-number size="mini" v-model="item.valueText" style="width: 100%;margin-top: 5px;"  disabled></el-input-number>
                </template >  
                <template v-else-if="item.cmd==131">   
                  <el-input-number size="mini" v-model="item.value" style="width: 100%;" :min="-999999.999" :max="999999.999"></el-input-number>
                  <el-input-number size="mini" v-model="item.valueText" style="width: 100%;margin-top: 5px;"  disabled></el-input-number>
                </template >  
                <template v-else-if="item.cmd==163">   
                  <el-input-number size="mini" v-model="item.value" style="width: 100%;" :min="0" :max="9.999"></el-input-number>
                  <el-input-number size="mini" v-model="item.valueText" style="width: 100%;margin-top: 5px;"  disabled></el-input-number>
                </template >  
                <template v-else-if="item.cmd==164">   
                  <el-input-number size="mini" v-model="item.value" style="width: 100%;" :min="-99.999" :max="99.999"></el-input-number>
                  <el-input-number size="mini" v-model="item.valueText" style="width: 100%;margin-top: 5px;"  disabled></el-input-number>
                </template >  
                <template v-else>
                  <el-input-number size="mini" v-model="item.value" style="width: 100%;" :min="-99999999999" :max="99999999999"></el-input-number>
                  <el-input-number size="mini" v-model="item.valueText" style="width: 100%;margin-top: 5px;"  disabled></el-input-number>
                </template>
              </div>
              <!-- <div style="width: 10%;margin-left:10px"> <el-button v-if="item.read==1" size="mini" type="primary" @click="readCommand(item)">读取</el-button> </div> -->
              <div style="width: 10%;margin-left:10px"> <el-button v-if="item.write==1" size="mini" type="success" @click="writeCommand(item)">写入</el-button></div>
            </div>
          </el-col>
          </template>
          
        </template>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button type="success" plain @click="refreshData()">刷新</el-button>
        <el-button type="primary" @click="configurationDialog = false">取消</el-button>
      </span>
    </el-dialog>
    <!-- 监测站固态存储数据 -->
    <el-dialog title="监测站固态存储数据" :visible.sync="jczDataDialog" width="80%" v-loading="hisLoading" >
      <el-form :model="listQuery" :inline="true">
        <el-form-item label="">
          <el-date-picker size="mini" format="yyyy-MM-dd HH" v-model="listQuery.timer" type="datetimerange" start-placeholder="开始日期" end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="">
          <el-button type="primary" size="mini" icon="el-icon-upload2" @click="getHistoryDatas">发送查看指令</el-button>
          <el-button type="primary" size="mini" icon="el-icon-search" @click="getHistoryData">接收设备数据</el-button>
          <br/>
          <span style="font-size: 12px;color: red;"><span style="color: red;"></span>Tips：发送查看指令后请点击“接收设备数据”按钮查看刷新信息，预计5分钟接收完全部数据信息。</span>
        </el-form-item>
      </el-form>
      <el-table :data="jczTableData" border style="width: 100%" align="center" height="500px">
        <el-table-column type="index" width="50"></el-table-column>
        <el-table-column :label="'设备编号'" show-overflow-tooltip min-width="180px" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.deviceNo}}</span>
          </template>
        </el-table-column>
        <el-table-column :label="'上报时间'" show-overflow-tooltip min-width="180px" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.last_active}}</span>
          </template>
        </el-table-column>
        <el-table-column :label="'采集时间'" show-overflow-tooltip min-width="180px" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.time}}</span>
          </template>
        </el-table-column>
        <el-table-column :label="'流量计水位'" show-overflow-tooltip align="center">
          <template slot-scope="scope">
            <span>{{scope.row.waterLevel_LLJ}}</span>
          </template>
        </el-table-column>

        <el-table-column :label="'流量计水深'" show-overflow-tooltip align="center">
          <template slot-scope="scope">
            <span>{{scope.row.waterDepth}}</span>
          </template>
        </el-table-column>

        <el-table-column :label="'水位计水位'" show-overflow-tooltip align="center">
          <template slot-scope="scope">
            <span>{{scope.row.WaterLevel_SWJ}}</span>
          </template>
        </el-table-column>
        <el-table-column :label="'累计流量'" show-overflow-tooltip align="center">
          <template slot-scope="scope">
            <span>{{scope.row.totalFlow}}</span>
          </template>
        </el-table-column>

        <el-table-column :label="'流速'" show-overflow-tooltip align="center">
          <template slot-scope="scope">
            <span>{{scope.row.flowVelocity}}</span>
          </template>
        </el-table-column>

        <el-table-column :label="'闸位'" show-overflow-tooltip align="center">
          <template slot-scope="scope">
            <span>{{scope.row.gatePosition}}</span>
          </template>
        </el-table-column>

        <el-table-column :label="'水温'" show-overflow-tooltip align="center">
          <template slot-scope="scope">
            <span>{{scope.row.waterTemperature}}</span>
          </template>
        </el-table-column>
        <el-table-column :label="'操作'" show-overflow-tooltip align="center">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" @click="transferHistory(scope.row)">转存历史</el-button>
          </template>
        </el-table-column>

      </el-table>
      <pagination :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="handleCurrentChanges" />
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="jczDataDialog = false" size="mini">确定</el-button>
      </div>
    </el-dialog>
  </div>

</template>

<script>
import * as basicDataApi from "@/api/basicDataApi/basicDataApi"; //基础资料接口
import Pagination from "@/components/Pagination";
import * as echarts from "echarts";
export default {
  name: "sbxx", //设备信息
  components: { Pagination },
  props: {
    rowData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    //设备类型
    // deviceType:[String, Object, Array],
    visible: {
      default: false,
    },
    //是否是详情，详情进入不可修改
    isCheckDetail: {
      default: false,
    },
  },
  directives: {},
  data() {
    return {
      backgroundImage:  "",//require('@/assets/device_images/10.jpg'),
      timeRecorder:true,
      timeRecorderId:null,
      sendCommandId:null,
      comLoading: false,
      dialogType:"dialogType",
      videoUrl:"",
      configurationInformation: {
        //基础配置
        MonitoringDtationPassword: "", //监测站密码
        WaterLevelBaseline: "", //水位基值
        WaterLevelBaselineUpperLimit: "", //水位基值上限
        WaterLevelBaselineLowerLimit: "", //水位基值下限
        InitialWaterVolume: "", //初始水量
        ReportingIntervalTime: "", //上报间隔时间
        CentralAddress: "", //主备信道中心站地址
        FlowLimit: "", //流量上限
        FlowCoefficient: "", //流量系数
        CutFlowSpeed: "", //切除流速
        MaximumGate: "", //闸门最大行程
        ControlMode: "", //控制模式
        GateControlSettingValue: "", //闸位控制设定值
        FlowControlSettingValue: "", //流量控制设定值
        Operator: "", //操作人
        OperatingMode: "", //操作方式
        FlowlevelCorrespondence: "", //流量-水位对应关系
      },
      basicInformation: {
        status:0,//在线状态
        //基本信息（通用）—— 对应数据库HSDevice
        DeviceTypeId: "HS_KFSCSBMQLLJ", //设备类型id  HS_CKYTHZM HS_KFSCSBMQLLJ
        DeviceNo: "", //设备编号
        VersionNo: "", //软件版本号
        InstalledLocation: "", //安装地点
        Longitude: "", //经度坐标
        Latitude: "", //纬度坐标
        WaterConsumptionNorm: "", //用水定额(m³)
        IrrigatedAcreage: "", //灌溉亩数(亩)
        CropTypeId: "", //作物类型
        FirstLevelBranchName: "", //一级分支机构
        SecLevelBranchName: "", //二级分支机构
        ThirdLevelBranchName: "", //三级分支机构
        InstalledImage: "", //设备安装图片
        DeviceRemark: "", //设备描述
        //设备分类信息
        DeliveryChannel: "2", //下发渠道 0未知 1本地 2:网页  3：小程序 
        Breakdown: "", //故障
        RunningStatus: "", //运行状态
        GateWidth: "", //闸板宽
        GateHeight: "", //闸板高
        GateMaximumStroke: "", //闸板最大行程
        GateFrontEquipment: "", //闸前设备
        GateBackEquipment: "", //闸后设备
        PowerSupplyMode: "", //供电方式
        MotorVoltage: "", //电机电压
        MotorPower: "", //电机功率
        MeasuringBoxWidth: "", //测箱宽h
        MeasuringBoxHeight: "", //测箱高w
        MeasuringBoxWiTransducerCountdth: "", //换能器数量n
        TransducerLowestLevelDistance: "", //最下层换能器距离底板距
        TransducerTopmostLevelDistance: "", //最上层换能器距离顶板距
        TransducerPattern: "", //换能器分布规律
        TransducerFrequency: "", //换能器频率
        WaterLevelSensorInstalledMethod: "", //水位传感器安装方式
        MeasuringBoxWiWaterLevelSensorCountdth: "", //水位传感器数量
        ChannelTopWidth: "", //渠道顶宽
        ChannelBottomWidth: "", //渠道底宽
        ChannelDeep: "", //渠道深度
        FirstTShapedWidth: "", //第一T型宽
        SecTShapedWidth: "", //第二T型宽
        FirstTShapedHeight: "", //第一T型高
        SecTShapedHeight: "", //第二T型高
        ChannelCount: "", //声道数
        DistributionRules: "", //分布规则
        ChannelInstalledHeight: "", //第1~N个声道的安装高度
        WaterLevelSensorInstalledHeight: "", //水位传感器安装高度
        SedimentationDetectionCount: "", //淤积检测点数量
        Slot: "", //槽号
        BValue: "", //B值
        WaterLevelGaugeInstalledHeight: "", //水位计安装高度
      },
      originalPassword:"",
      configuration:[],
      configurationDeep: [
        {
          cmd: 150,
          value: "",
          read: 0,
          write: 1,
          label: "修改密码",
        },
        {
          cmd: 166,
          value: "",
          read: 0,
          write: 1,
          label: "自报时间间隔",
        },
      
        {
          cmd: 131,
          value: "",
          read: 1,
          write: 1,
          label: "流量参数上限",
        },
        {
          cmd: 163,
          value: "",
          read: 1,
          write: 1,
          label: "流量系数",
        },
        {
          cmd: 164,
          value: "",
          read: 1,
          write: 1,
          label: "切除流速",
        },
        {
          cmd: 127,
          value: "",
          read: 0,
          write: 1,
          label: "水量初始值",
        },
        {
          cmd: 101,
          value:[],
          read: 1,
          write: 1,
          label: "基值上下限",
        },
        {
          cmd: 165,
          value: [{label:"水位1",value:0},{label:"流量1",value:0},{label:"水位2",value:0},{label:"流量2",value:0},{label:"水位3",value:0},{label:"流量3",value:0},{label:"水位4",value:0},{label:"流量4",value:0},{label:"水位5",value:0},{label:"流量5",value:0}],
          read: 1,
          write: 1,
          label: "水位流量关系",
        },
        {
          cmd: 162,
          // value: "",
          value: [{label:"主站地址",value:""},{label:"备站地址",value:""}],
          read: 1,
          write: 1,
          label: "主备信道中心站地址",
        },
      ],
      swxDatas: [],
      swd1: [],
      llxDatas: [],
      lld1: [],
      hisLoading: false,
      total: 0,
      listQuery: {
        timer: [
          new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000),
          new Date(),
        ],
        StartTime: "",
        EndTime: "",
        page: 1,
        limit: 50,
      },
      upConfiguration: {},
      activeName: "first",
      rowHeight: 0, //行高
      // basicInformation:  {
      //   //基本信息（通用）—— 对应数据库HSDevice
      //   DeviceTypeId: "HS_KFSCSBMQLLJ", //设备类型id  HS_CKYTHZM HS_KFSCSBMQLLJ
      //   DeviceNo: "", //设备编号
      //   VersionNo: "", //软件版本号
      //   InstalledLocation: "", //安装地点
      //   Longitude: "", //经度坐标
      //   Latitude: "", //纬度坐标
      //   WaterConsumptionNorm: "", //用水定额(m³)
      //   IrrigatedAcreage: "", //灌溉亩数(亩)
      //   CropTypeId: "", //作物类型
      //   FirstLevelBranchName: "", //一级分支机构
      //   SecLevelBranchName: "", //二级分支机构
      //   ThirdLevelBranchName: "", //三级分支机构
      //   InstalledImage: "", //设备安装图片
      //   DeviceRemark: "", //设备描述
      //   //设备分类信息
      //   DeliveryChannel:'',//下发渠道
      //   Breakdown:'',//故障
      //   RunningStatus:'',//运行状态
      //   GateWidth: "", //闸板宽
      //   GateHeight: "", //闸板高
      //   GateMaximumStroke: "", //闸板最大行程
      //   GateFrontEquipment: "", //闸前设备
      //   GateBackEquipment: "", //闸后设备
      //   PowerSupplyMode: "", //供电方式
      //   MotorVoltage: "", //电机电压
      //   MotorPower: "", //电机功率
      //   MeasuringBoxWidth: "", //测箱宽h
      //   MeasuringBoxHeight: "", //测箱高w
      //   MeasuringBoxWiTransducerCountdth: "", //换能器数量n
      //   TransducerLowestLevelDistance: "", //最下层换能器距离底板距
      //   TransducerTopmostLevelDistance: "", //最上层换能器距离顶板距
      //   TransducerPattern: "", //换能器分布规律
      //   TransducerFrequency: "", //换能器频率
      //   WaterLevelSensorInstalledMethod: "", //水位传感器安装方式
      //   MeasuringBoxWiWaterLevelSensorCountdth: "", //水位传感器数量
      //   ChannelTopWidth: "", //渠道顶宽
      //   ChannelBottomWidth: "", //渠道底宽
      //   ChannelDeep: "", //渠道深度
      //   FirstTShapedWidth: "", //第一T型宽
      //   SecTShapedWidth: "", //第二T型宽
      //   FirstTShapedHeight: "", //第一T型高
      //   SecTShapedHeight: "", //第二T型高
      //   ChannelCount: "", //声道数
      //   DistributionRules: "", //分布规则
      //   ChannelInstalledHeight: "", //第1~N个声道的安装高度
      //   WaterLevelSensorInstalledHeight: "", //水位传感器安装高度
      //   SedimentationDetectionCount: "", //淤积检测点数量
      //   Slot: "", //槽号
      //   BValue: "", //B值
      //   WaterLevelGaugeInstalledHeight: "", //水位计安装高度
      // },
      // configurationInformation: {
      //   //基础配置
      //   MonitoringDtationPassword: "", //监测站密码
      //   WaterLevelBaseline: "", //水位基值
      //   WaterLevelBaselineUpperLimit: "", //水位基值上限
      //   WaterLevelBaselineLowerLimit: "", //水位基值下限
      //   InitialWaterVolume: "", //初始水量
      //   ReportingIntervalTime: "", //上报间隔时间
      //   CentralAddress: "", //主备信道中心站地址
      //   FlowLimit: "", //流量上限
      //   FlowCoefficient: "", //流量系数
      //   CutFlowSpeed: "", //切除流速
      //   MaximumGate: "", //闸门最大行程
      //   ControlMode: "", //控制模式
      //   GateControlSettingValue: "", //闸位控制设定值
      //   FlowControlSettingValue: "", //流量控制设定值
      //   Operator: "", //操作人
      //   OperatingMode: "", //操作方式
      //   FlowlevelCorrespondence: "", //流量-水位对应关系
      // },
      checkType: 1, //查看明细1\配置信息2
      detailsDialog: false, //明细弹窗
      configurationDialog: false, //配置弹窗
      parameterForm: {
        MonitoringDtationPassword: "", //监测站密码
        WaterLevelBaseline: "", //水位基值
        WaterLevelBaselineUpperLimit: "", //水位基值上限
        WaterLevelBaselineLowerLimit: "", //水位基值下限
        InitialWaterVolume: "", //初始水量
        ReportingIntervalTime: "", //上报间隔时间
        CentralAddress: "", //主备信道中心站地址
        FlowLimit: "", //流量上限
        FlowCoefficient: "", //流量系数
        CutFlowSpeed: "", //切除流速
        MaximumGate: "", //闸门最大行程
        ControlMode: "", //控制模式
        GateControlSettingValue: "", //闸位控制设定值
        FlowControlSettingValue: "", //流量控制设定值
        Operator: "", //操作人
        OperatingMode: "", //操作方式
        FlowlevelCorrespondence: "", //流量-水位对应关系
      },
      jczDataDialog: false, //监测站固态存储数据弹窗
      jczTableData: [],
      // 控制表单
      controlForm: {
        PlanName: "", //计划名称
        ControlMode: "1", //控制模式
        controlParam: "", //控制参数
        ExecuteImmediately: true, //立即执行
        executeStartTime: "", //开始时间
        executeEndTime: "", //结束时间
        openGatePwd: "", //开闸密码
      },
      // 控制表单校验
      ontrolRules: {
        PlanName: [
          { required: true, message: "请输入计划名称", trigger: "blur" },
        ],
        ControlMode: [
          { required: true, message: "请选择控制模式", trigger: "change" },
        ],
        controlParam: [
          { required: true, message: "请输入控制参数", trigger: "blur" },
        ],
        openGatePwd: [
          { required: true, message: "请输入开闸密码", trigger: "blur" },
        ],
        executeStartTime: [
          { required: true, message: "请输入开始时间", trigger: "blur" },
        ],
        executeEndTime: [
          { required: true, message: "请输入结束时间", trigger: "blur" },
        ],
      },
      // 计划列表
      listQueryPlan: {
        // 查询条件
        page: 1,
        limit: 50,
        DeviceId: "",
      },
      firstHeaderListPlan: [],
      tableTotalPlan: 0, //明细条数
      tableDataPlan: [
        {
          jhmc: "测试计划",
          jhnr: "测试一小时后执行",
          zxcs: 1,
          zt: "正在运行",
          zxsj: "2023-12-25 10:12:38",
          bz: "",
        },
      ],
      tableHeightPlan: 267, //表格高度
      // otherColumn:[//
      //   {
      //     label:'操作',
      //     slotName:'operate',
      //   }
      // ],
      // 计划列表
      RealTimeWaterLevel: "", //顶部折现图分站每日水量统计-用于屏幕放大缩小重新渲染
      RealTimeTraffic: "", //顶部折现图分站月度水量统计-用于屏幕放大缩小重新渲染
      chartHeight: 170, //图表高度
    };
  },

  computed: {
    dictionary(dicId, value) {
      return function (dicId, value) {
        let disData = this.$store.state.allCategorysData.filter((item) => {
          return item.id == dicId;
        });
        if (disData && disData.length > 0) {
          let result = "";
          disData[0].categoryList.forEach((item) => {
            if (item.dtCode == value) {
              result = item.name;
            }
          });
          return result;
        } else {
          return "";
        }
      };
    },
    getAlarmStatus(val){
      return function(val){
        if(val==0){
          return "不报警"
        }else if(val == 1){
          return "报警"
        }else{
          return "-"
        }
      }
    }
  },
  filters: {},
  created() {},
  beforeRouteLeave(to, from, next) {
      if (this.$store.state.sbxxTimer) {
        clearInterval(this.$store.state.sbxxtimer);
      }
      if (this.$store.state.setTimeoutId) {
        clearTimeout(this.$store.state.setTimeoutId);
      }
      next();
   },
  beforeDestroy() {
    if (this.$store.state.sbxxTimer) {
      clearInterval(this.$store.state.sbxxtimer);
    }
    if (this.$store.state.setTimeoutId) {
      clearTimeout(this.$store.state.setTimeoutId);
    }
  },
  mounted() { 
    let dataSource =JSON.parse(JSON.stringify(JSON.parse(this.$route.query.row)));
    for (let key in dataSource) {
      let newKey = key.charAt(0).toUpperCase() + key.slice(1);
      this.basicInformation[newKey] = dataSource[key];
    }
    try{
      this.backgroundImage = require('@/assets/device_images/'+this.basicInformation.Icon+'.jpg')
    }catch{
      this.backgroundImage=""
    }
    // console.log(this.basicInformation,7777)
    if (this.$store.state.setTimeoutId) {
      clearTimeout(this.$store.state.setTimeoutId);
    }
    // 获取实时数据
    this.getDeviceInfoAndRealData(); 
    // console.log(this.rowData,788888888)
    basicDataApi.cpxqGetYSYToken({key: this.rowData.accessKey,secret:this.rowData.accessSecret}).then((res)=>{
      if(res.code == 200){
        this.videoUrl = "https://open.ys7.com/ezopen/h5/iframe?url=" + this.rowData.accessURL + "&accessToken=" + res.result.data.accessToken
      }else{
        this.videoUrl=""
      }
    })
    if (this.rowData.deviceTypeId == "HS_CKYTHZM") {
        this.getList();
        this.configurationDeep.forEach((item)=>{
          if(item.cmd ==101){
            item.value=[[{label:"基值1",value:0},{label:"基值2",value:0},{label:"基值3",value:0}],[{label:"上限1",value:0},{label:"上限2",value:0},{label:"上限3",value:0}],[{label:"下限1",value:0},{label:"下限2",value:0},{label:"下限3",value:0}]]
          }
        })
    }else if(this.rowData.deviceTypeId == "HS_SWJ"){
      this.configurationDeep.forEach((item)=>{
          if(item.cmd ==101){
            item.value=[[{label:"基值1",value:0}],[{label:"上限1",value:0}],[{label:"下限1",value:0}]]
          }
        })
    }else{
      this.configurationDeep.forEach((item)=>{
          if(item.cmd ==101){
            item.value=[[{label:"基值1",value:0},{label:"基值2",value:0}],[{label:"上限1",value:0},{label:"上限2",value:0}],[{label:"下限1",value:0},{label:"下限2",value:0}]]
          }
        })
    }
   
    let elec = document.getElementById("echarts_box_height");
    this.chartHeight = elec.offsetHeight - 40;

    // 计算table高度
    setTimeout(() => {
        // table高度
        let ele = document.getElementById("table_box_height");
      if (ele) {
        this.tableHeightPlan = ele.offsetHeight - 110;
        window.addEventListener("resize", () => {
          let ele = document.getElementById("table_box_height");
          this.tableHeightPlan = ele.offsetHeight - 110;
          this.$forceUpdate();
        });
      }
      // 计算table高度
    }, 500);

    // echarts高度
    window.addEventListener("resize", () => {
      elec = document.getElementById("echarts_box_height");
      if(elec){
        this.chartHeight = elec.offsetHeight - 40;
        this.$nextTick(() => {
        this.RealTimeWaterLevel.resize();
        this.RealTimeTraffic.resize();
      });
      }
    });
  },
  watch: {
  //   '$route': { // $route可以用引号，也可以不用引号  监听的对象
  //       handler(to, from){
  //         console.log('路由变化了')
  //         console.log('当前页面路由：' + to);
  //         console.log('上一个路由：' + from);
  //       },
  //         deep: true, // 深度观察监听 设置为 true
  //         immediate: true, // 第一次初始化渲染就可以监听到
  //     }
  },
  methods: {
    //手动刷新数据
    refreshData(){
      // 获取实时数据
      this.getDeviceInfoAndRealData(); 
    },
    handleInputPwds() {
      // 使用正则表达式过滤非数字字符
      this.controlForm.openGatePwd = this.controlForm.openGatePwd.replace(/\D/g, '').substring(0, 4);
    },
    handleInputPwd() {
      // 使用正则表达式过滤非数字字符
      this.originalPassword = this.originalPassword.replace(/\D/g, '').substring(0, 4);
    },
    handleInputPwds(){
      this.configuration.forEach((item)=>{
        if(item.cmd==150){
          item.value = item.value.replace(/\D/g, '').substring(0, 4);
        }
      })
    },
    // 读取数据
    getHistoryDatas(){
      var searchQuery = Object.assign({}, this.listQuery);
      if (searchQuery.timer && searchQuery.timer.length == 2) {
        searchQuery.StartTime = this.$basicFun.dataFormat(
          searchQuery.timer[0],
          "yyyy-MM-dd hh:00:00"
        );
        searchQuery.EndTime = this.$basicFun.dataFormat(
          searchQuery.timer[1],
          "yyyy-MM-dd hh:mm:ss"
        );
      } else {
        this.$message.error("请先选择时间在发送指令");
        return;
      }
      let isAdd =true //是否+1
       if(searchQuery.EndTime.substring(searchQuery.EndTime.indexOf(" ")+4,searchQuery.EndTime.indexOf(" ")+6)=='00'&searchQuery.EndTime.substring(searchQuery.EndTime.indexOf(" ")+7,searchQuery.EndTime.indexOf(" ")+9)=="00"){
        isAdd =false
       }
       let newTime=""
       if(isAdd==true){
        let house =Number(searchQuery.EndTime.substring(searchQuery.EndTime.indexOf(" ")+1,searchQuery.EndTime.indexOf(" ")+3))+1
        if(house<10){
          newTime = searchQuery.EndTime.substring(0,searchQuery.EndTime.indexOf(" ")+1)+"0"+house+":00:00"
        }else{
          newTime = searchQuery.EndTime.substring(0,searchQuery.EndTime.indexOf(" ")+1)+house+":00:00"
        }
       
       }else{
        newTime = searchQuery.EndTime.substring(0,searchQuery.EndTime.indexOf(" ")+1)+searchQuery.EndTime.substring(searchQuery.EndTime.indexOf(" ")+1,searchQuery.EndTime.indexOf(" ")+3)+":00:00"
       }
       let subTimeData = searchQuery.StartTime+"_"+newTime
      let subData = {
        deviceNo: this.basicInformation.DeviceNo,
        cmd: 182,
        type: 1,
        value: subTimeData,
        pwd: "",
      };
      basicDataApi.sbxqSendCommand(subData).then((res)  => {
        if(res.code ==200&&res.result.executeState==1){
          this.$message.success('读取指令下发成功')
        }else{
          this.$message.error('读取指令下发失败')
        }
      }).catch(()=>{
        this.$message.error('读取指令下发失败')
      })
    },
    // 读取设置
    readCommand(row) {
      let subData = {
        deviceNo: this.basicInformation.DeviceNo,
        cmd: row.cmd,
        type: 1,
        value: row.value,
        pwd: "",
      };
      basicDataApi.sbxqSendCommand(subData).then((res) => {
        // console.log(res,7777777)
      });
    },
    // 写入
    writeCommand(row) {
      let subData ={}
        if(row.cmd == 150){
          if(this.originalPassword==''){
            this.$message.error('请输入原密码')
            return
          }
          subData = {
            deviceNo: this.basicInformation.DeviceNo,
            cmd: row.cmd,
            type: 2,
            value: row.value,
            pwd: this.originalPassword,
          };
        }
        else if(row.cmd == 101){
          let newValue = ""
          if(this.rowData.deviceTypeId == 'HS_CKYTHZM'){
            newValue =  row.value[0][0].value+"_"
            newValue +=  row.value[2][0].value+"_"
            newValue +=  row.value[1][0].value+"_"
            newValue +=  row.value[0][1].value+"_"
            newValue +=  row.value[2][1].value+"_"
            newValue +=  row.value[1][1].value+"_"
            newValue +=  row.value[0][2].value+"_"
            newValue +=  row.value[2][2].value+"_"
            newValue +=  row.value[1][2].value
          }else if(this.rowData.deviceTypeId == 'HS_SWJ'){
            // item.value=[[{label:"基值1",value:0}],[{label:"上限1",value:0}],[{label:"下限1",value:0}]]
            newValue =  row.value[0][0].value+"_"
            newValue +=  row.value[2][0].value+"_"
            newValue +=  row.value[1][0].value
          }else{
            newValue =  row.value[0][0].value+"_"
            newValue +=  row.value[2][0].value+"_"
            newValue +=  row.value[1][0].value+"_"
            newValue +=  row.value[0][1].value+"_"
            newValue +=  row.value[2][1].value+"_"
            newValue +=  row.value[1][1].value
          }
          subData = {
            deviceNo: this.basicInformation.DeviceNo,
            cmd: row.cmd,
            type: 2,
            value: newValue,
            pwd: "",
          };

          }else if(row.cmd == 162){
            let a=true
            let newValue = ""
            row.value.forEach((item)=>{
              if(this.validateIPv4(item.value)==false){
                a=false
              }
              newValue +=item.value+"_"
              
            })
            if(a==false){
              this.$message.error('请输入正确的IP')
              return
            }
            newValue = newValue.substring(0,newValue.length-1)
              subData = {
              deviceNo: this.basicInformation.DeviceNo,
              cmd: row.cmd,
              type: 2,
              value: newValue,
              pwd: "",
            };
          }else if(row.cmd == 165){
            let newValue = ""
          row.value.forEach((item)=>{
            newValue +=item.value+"_"
            
          })
          newValue = newValue.substring(0,newValue.length-1)
            subData = {
            deviceNo: this.basicInformation.DeviceNo,
            cmd: row.cmd,
            type: 2,
            value: newValue,
            pwd: "",
          };
          }
        else{
          subData = {
          deviceNo: this.basicInformation.DeviceNo,
          cmd: row.cmd,
          type: 2,
          value: row.value,
          pwd: "",
        };
      }
      if(row.cmd == 127||row.cmd == 166){  
        // 密码修改需要特殊处理 第一步发送，第二部查询执行， 第三步保存   comLoading  +2
        // 第一步发送
        basicDataApi.sbxqSendCommand(subData).then((res) => {
          if(res.code ==200&&res.result.executeState==1){
            // 第二部查询执行
            this.comLoading =true
            this.timeRecorder = true
            this.timeRecorderId=null
            this.timeRecorderId=setTimeout(() => {
              this.timeRecorder = false
            }, 65000);
            this.sbxqSendCommandMM(subData)
          }else{
            this.$message.error(res.result.remark)
          }
        }).catch(()=>{
          this.$message.error('写入失败')
        })

      }else{
        basicDataApi.sbxqSendCommand(subData).then((res) => {
          if(res.code ==200&&res.result.executeState==1){
            this.$message.success('写入指令下发成功')
          }else{
            this.$message.error(res.result.remark)
          }
        }).catch(()=>{
          this.$message.error('写入指令下发失败')
        })
      }
    },
    validateIPv4(ip) {
      const pattern = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?):([0-9]{1,5})$/;
      return pattern.test(ip);
    },
    sbxqSendCommandMM(subData){
      if(this.timeRecorder ==false ){
        this.comLoading =false
        this.$message.error("超时访问")
        return
      }
      basicDataApi.sbxqSendCommandSchedule(subData).then((res) => {
        if(res.code ==200&&this.$func.commandGudgment(JSON.parse(res.result.executeResult).flag1) == 2){
          clearTimeout(this.timeRecorderId)
          this.timeRecorder = true
          // 第三步 保存配置
          basicDataApi.sbxqSetDeviceConfig(subData).then((res) => {
          if(res.code ==200){
            this.comLoading =false
            this.$message.success('写入成功')
          }else{
            this.comLoading =false
            this.$message.error('写入失败')
          }
        }).catch(()=>{
          this.comLoading =false
          this.$message.error('写入失败')
        })

        }else if(res.code ==200&&this.$func.commandGudgment(JSON.parse(res.result.executeResult).flag1) == 1){
          setTimeout(() => {
            this.sbxqSendCommandMM(subData)
          }, 1000);
        }else{
          this.comLoading =false
          this.$message.error(res.result.remark)
        }
      }).catch(()=>{
        this.comLoading =false
        this.$message.error('写入失败')
      })
    },
    // 获取试实时数据
    // realTime() {
    //   setTimeout(() => {
    //     // this.getDeviceInfoAndRealData();
    //   }, 1000);
    // },
    getDeviceInfoAndRealData() {
      basicDataApi
        .cpxqGetDeviceInfoAndRealData({
          DeviceId: this.rowData.id,
          DeviceNo: this.rowData.deviceNo,
        })
        .then((res) => {
          if (res.code == 200) {
            let newResult = {};
            for (let key in res.result) {
              let newKey = key.charAt(0).toUpperCase() + key.slice(1);
              newResult[newKey] = res.result[key];
            }
            this.upConfiguration = newResult;
            newResult.WaterLevelBaselines = newResult.WaterLevelBaseline1+"_"+newResult.WaterLevelBaselineLowerLimit1+"_"+newResult.WaterLevelBaselineUpperLimit1+"_"+newResult.WaterLevelBaseline2+"_"+newResult.WaterLevelBaselineLowerLimit2+"_"+newResult.WaterLevelBaselineUpperLimit2+"_"+newResult.WaterLevelBaseline3+"_"+newResult.WaterLevelBaselineLowerLimit3+"_"+newResult.WaterLevelBaselineUpperLimit3
            this.basicInformation = newResult;
            try{
              this.backgroundImage = require('@/assets/device_images/'+this.basicInformation.Icon+'.jpg')
            }catch{
              this.backgroundImage=""
            }
            this.upconfigurationData()
         
            // this.$emit("upBasicInformation", newResult);
            if (this.basicInformation.DeviceTypeId != "HS_CKYTHZM") {
              let shuiwei = [];
              let liuliang = [];
              if (newResult.HsRealDataList.length > 0) {
                newResult.HsRealDataList.forEach((item) => {
                  item.CurReadTime = newResult.CurReadTime
                  if(this.basicInformation.DeviceTypeId=='HS_SWJ'){
                    if (item.var_index == "003") {
                    //表示水位
                      shuiwei.push(item);
                    } else if (item.var_index == "004") {
                      //表示流量
                      liuliang.push(item);
                    }
                  }else{
                    if (item.var_index == "001") {
                      //表示水位
                      shuiwei.push(item);
                    } else if (item.var_index == "004") {
                      //表示流量
                      liuliang.push(item);
                    }
                  }
                });
              }
              this.shuiweiData(shuiwei);
              this.liuliangData(liuliang);
            }
            if(this.$route.path=="/hsycjkequipmentmanagement/details" &&this.basicInformation.Status ==1){
              this.$store.state.setTimeoutId = setTimeout(() => {
                this.getDeviceInfoAndRealData();
              }, 1000 * 60 * 5);
            }else{
              if (this.$store.state.setTimeoutId) {
                clearTimeout(this.$store.state.setTimeoutId);
              }
            }
          } else {
            if(this.$route.path=="/hsycjkequipmentmanagement/details" &&this.basicInformation.Status ==1){
              this.$store.state.setTimeoutId = setTimeout(() => {
                this.getDeviceInfoAndRealData();
              }, 1000 * 60 * 5);
            }else{
              if (this.$store.state.setTimeoutId) {
                clearTimeout(this.$store.state.setTimeoutId);
              }
            }
          }
        })
        .catch(() => {
          if(this.$route.path=="/hsycjkequipmentmanagement/details" &&this.basicInformation.Status ==1){
              this.$store.state.setTimeoutId = setTimeout(() => {
                this.getDeviceInfoAndRealData();
              },  1000 * 60 * 5);
            }else{
              if (this.$store.state.setTimeoutId) {
                clearTimeout(this.$store.state.setTimeoutId);
              }
            }
        });
    },
    // 水位获取数据之后画图
    shuiweiData(data) {
      let xDatas = [];
      let d1 = [];
      data.forEach((item) => {
        xDatas.push(item.CurReadTime);
        d1.push(item.value);
      });
      this.swxDatas.push(...xDatas);
      this.swd1.push(...d1);
      this.getDailyChart(this.swxDatas, this.swd1);
    },
    // 流量获取数据之后画图
    liuliangData(data) {
      let xDatas = [];
      let d1 = [];
      data.forEach((item) => {
        xDatas.push(item.CurReadTime);
        d1.push(item.value);
      });
      this.llxDatas.push(...xDatas);
      this.lld1.push(...d1);
      this.getMonthlyChart(this.llxDatas, this.lld1);
    },
    // 转存历史
    transferHistory(row) {
      this.hisLoading = true;
      let subData = JSON.parse(JSON.stringify(row));
      let variable = [];
      subData.hsDeviceOrgRealdata.forEach((item) => {
        let ss = item;
        ss.history_data = [{ time: item.time, value: item.value }];
        variable.push(ss);
      });
      subData.variable = variable;
      subData.source = 1;
      basicDataApi
        .lssjAddOrUpdate(subData)
        .then((response) => {
          if (response.code == 200) {
            this.$message.success("转存成功");
            this.hisLoading = false;
          } else {
            this.$message.error("转存失败");
            this.hisLoading = false;
          }
        })
        .catch(() => {
          this.hisLoading = false;
        });
    },
    // 历史查询
    getHistoryData() {
      var searchQuery = Object.assign({}, this.listQuery);
      if (searchQuery.timer && searchQuery.timer.length == 2) {
        searchQuery.StartTime = this.$basicFun.dataFormat(
          searchQuery.timer[0],
          "yyyy-MM-dd hh:mm:ss"
        );
        searchQuery.EndTime = this.$basicFun.dataFormat(
          searchQuery.timer[1],
          "yyyy-MM-dd hh:mm:ss"
        );
      } else {
        this.$message.error("请先选择时间在接收数据");
        return;
      }
      searchQuery.DeviceNo = this.basicInformation.DeviceNo;
      searchQuery.DeviceId = this.basicInformation.Id;
      this.hisLoading =true
      basicDataApi
        .GetDeviceInfoHistoryDataFromHS(searchQuery)
        .then((response) => {
          if (response.code == 200) {
            this.jczTableData = response.data;
            this.total = response.count;
          } else {
            this.jczTableData = [];
            this.total = 0;
          }
          this.hisLoading =false
        })
        .catch(() => {
          this.jczTableData = [];
          this.total = 0;
          this.hisLoading =false
        });
    },
    // 清空时间
    chExecuteImmediately() {
      this.controlForm.executeStartTime = "";
      this.controlForm.executeEndTime = "";
    },
    // 获取数据
    getList() {
      this.loading = true;
      this.listQueryPlan.DeviceId=this.rowData.id
      basicDataApi
        .cpxqHSDevicePlansLoad(this.listQueryPlan)
        .then((response) => {
          if (response.code == 200) {
            response.columnHeaders.forEach((item) => {
              // 首字母小写
              item.key =
                item.key.substring(0, 1).toLowerCase() + item.key.substring(1);
            });
            this.firstHeaderListPlan = response.columnHeaders.filter(
              (u) => u.browsable && u.isList
            );
            this.firstHeaderListPlan.forEach((i) => {
              if (i.key == "surfaceNum") {
                i.columnWidth = 160;
              }
            });
            this.tableDataPlan = response.data;
            this.tableTotalPlan = response.count;
          } else {
            this.firstHeaderListPlan = [];
            this.tableDataPlan = [];
            this.$notify({
              message: response.message,
              type: "warning",
              duration: 2000,
            });
          }
          this.loading = false;
        })
        .catch(() => {
          this.firstHeaderListPlan = [];
          this.tableDataPlan = [];
          this.loading = false;
        });
    },
    openConDialog() {
      this.originalPassword =""
      let data = JSON.parse(JSON.stringify(this.configurationDeep))
      if(this.basicInformation.DeviceTypeId=="HS_CKYTHZM"){
        this.configuration =data.filter((item)=>{ return item.cmd !=165})
      }else if(this.basicInformation.DeviceTypeId=="HS_SWJ"){
        this.configuration =data.filter((item)=>{ return item.cmd !=165 && item.cmd !=127 })
      }else if(this.basicInformation.DeviceTypeId=="HS_SWLLJ"){
        this.configuration = data
      }else{
        this.configuration =data.filter((item)=>{ return item.cmd !=165})
      }
      this.dialogType =new Date().getTime()
      this.upconfigurationData()
      setTimeout(() => {
        this.configurationDialog = true;
      }, 200);
    },
    upconfigurationData(){
      this.configuration.forEach((item)=>{
        if(item.cmd == 150){ //自报时间间隔
          item.valueText = this.basicInformation.MonitoringDtationPassword
        }
        if(item.cmd == 166){ //自报时间间隔
          item.valueText = this.basicInformation.ReportingIntervalTime
        }
        if(item.cmd == 131){ //流量参数上限
          item.valueText = this.basicInformation.FlowLimit
        }
        if(item.cmd == 163){ //流量系数
          item.valueText = this.basicInformation.FlowCoefficient
        }
        if(item.cmd == 164){ //切除流速
          item.valueText = this.basicInformation.CutFlowSpeed
        }
        if(item.cmd == 127){ //改为累计流量
          item.valueText = this.basicInformation.TotalFlow
        }
        if(item.cmd == 101){ //水量初始值
          if (this.rowData.deviceTypeId == "HS_CKYTHZM") {

              // item.value=[[{label:"基值1",value:0,valueText:this.basicInformation.WaterLevelBaseline1},{label:"基值2",value:0,valueText:this.basicInformation.WaterLevelBaseline2},{label:"基值3",value:0,valueText:this.basicInformation.WaterLevelBaseline3}],[{label:"上限1",value:0,valueText:this.basicInformation.WaterLevelBaselineUpperLimit1},{label:"上限2",value:0,valueText:this.basicInformation.WaterLevelBaselineUpperLimit2},{label:"上限3",value:0,valueText:this.basicInformation.WaterLevelBaselineUpperLimit3}],[{label:"下限1",value:0,valueText:this.basicInformation.WaterLevelBaselineLowerLimit1},{label:"下限2",value:0,valueText:this.basicInformation.WaterLevelBaselineLowerLimit2},{label:"下限3",value:0,valueText:this.basicInformation.WaterLevelBaselineLowerLimit3}]]
              item.value[0][0].valueText = this.basicInformation.WaterLevelBaseline1
              item.value[2][0].valueText = this.basicInformation.WaterLevelBaselineLowerLimit1
              item.value[1][0].valueText = this.basicInformation.WaterLevelBaselineUpperLimit1
              item.value[0][1].valueText = this.basicInformation.WaterLevelBaseline2
              item.value[2][1].valueText = this.basicInformation.WaterLevelBaselineLowerLimit2
              item.value[1][1].valueText = this.basicInformation.WaterLevelBaselineUpperLimit2
              item.value[0][2].valueText = this.basicInformation.WaterLevelBaseline3
              item.value[2][2].valueText = this.basicInformation.WaterLevelBaselineLowerLimit3
              item.value[1][2].valueText = this.basicInformation.WaterLevelBaselineUpperLimit3
          }else if(this.rowData.deviceTypeId == "HS_SWJ"){
              // item.value=[[{label:"基值1",value:0,valueText:this.basicInformation.WaterLevelBaseline1}],[{label:"上限1",value:0,valueText:this.basicInformation.WaterLevelBaselineUpperLimit1}],[{label:"下限1",value:0,valueText:this.basicInformation.WaterLevelBaselineLowerLimit1}]]
              item.value[0][0].valueText = this.basicInformation.WaterLevelBaseline1
              item.value[2][0].valueText = this.basicInformation.WaterLevelBaselineLowerLimit1
              item.value[1][0].valueText = this.basicInformation.WaterLevelBaselineUpperLimit1
          }else{
              // item.value=[[{label:"基值1",value:0,valueText:this.basicInformation.WaterLevelBaseline1},{label:"基值2",value:0,valueText:this.basicInformation.WaterLevelBaseline2}],[{label:"上限1",value:0,valueText:this.basicInformation.WaterLevelBaselineUpperLimit1},{label:"上限2",value:0,valueText:this.basicInformation.WaterLevelBaselineUpperLimit2}],[{label:"下限1",value:0,valueText:this.basicInformation.WaterLevelBaselineLowerLimit1},{label:"下限2",value:0,valueText:this.basicInformation.WaterLevelBaselineLowerLimit2}]]
              item.value[0][0].valueText = this.basicInformation.WaterLevelBaseline1
              item.value[2][0].valueText = this.basicInformation.WaterLevelBaselineLowerLimit1
              item.value[1][0].valueText = this.basicInformation.WaterLevelBaselineUpperLimit1
              item.value[0][1].valueText = this.basicInformation.WaterLevelBaseline2
              item.value[2][1].valueText = this.basicInformation.WaterLevelBaselineLowerLimit2
              item.value[1][1].valueText = this.basicInformation.WaterLevelBaselineUpperLimit2
          }
        }
        if(item.cmd == 165){ //水位流量关系
          // [{label:"水位1",value:0},{label:"流量1",value:0},{label:"水位2",value:0},{label:"流量2",value:0},{label:"水位3",value:0},{label:"流量3",value:0},{label:"水位4",value:0},{label:"流量4",value:0},{label:"水位5",value:0},{label:"流量5",value:0}],
          let FlowlevelCorrespondence1=  this.basicInformation.FlowlevelCorrespondence1?this.basicInformation.FlowlevelCorrespondence1.split("_"):[]
          item.value[0].valueText =  FlowlevelCorrespondence1[0]?FlowlevelCorrespondence1[0]:0
          item.value[1].valueText =  FlowlevelCorrespondence1[1]?FlowlevelCorrespondence1[1]:0
          let FlowlevelCorrespondence2=  this.basicInformation.FlowlevelCorrespondence2?this.basicInformation.FlowlevelCorrespondence2.split("_"):[]
          item.value[2].valueText =  FlowlevelCorrespondence2[0]?FlowlevelCorrespondence2[0]:0
          item.value[3].valueText =  FlowlevelCorrespondence2[1]?FlowlevelCorrespondence2[1]:0
          let FlowlevelCorrespondence3=  this.basicInformation.FlowlevelCorrespondence3?this.basicInformation.FlowlevelCorrespondence3.split("_"):[]
          item.value[4].valueText =  FlowlevelCorrespondence3[0]?FlowlevelCorrespondence3[0]:0
          item.value[5].valueText =  FlowlevelCorrespondence3[1]?FlowlevelCorrespondence3[1]:0
          let FlowlevelCorrespondence4=  this.basicInformation.FlowlevelCorrespondence4?this.basicInformation.FlowlevelCorrespondence4.split("_"):[]
          item.value[6].valueText =  FlowlevelCorrespondence4[0]?FlowlevelCorrespondence4[0]:0
          item.value[7].valueText =  FlowlevelCorrespondence4[1]?FlowlevelCorrespondence4[1]:0
          let FlowlevelCorrespondence5=  this.basicInformation.FlowlevelCorrespondence5?this.basicInformation.FlowlevelCorrespondence5.split("_"):[]
          item.value[8].valueText =  FlowlevelCorrespondence5[0]?FlowlevelCorrespondence5[0]:0
          item.value[9].valueText =  FlowlevelCorrespondence5[1]?FlowlevelCorrespondence5[1]:0
        }
        if(item.cmd == 162){ //主备信道中心站地址
          // [{label:"主站地址",value:""},{label:"备站地址",value:""}],
          let CentralAddress=  this.basicInformation.CentralAddress?this.basicInformation.CentralAddress.split("_"):[]
          item.value[0].valueText =  CentralAddress[0]?CentralAddress[0]:0
          item.value[1].valueText =  CentralAddress[1]?CentralAddress[1]:0
        }
      })
    },
    //明细弹窗
    goCheck(type) {
      this.checkType = type;
      this.detailsDialog = true;
      return;
      basicDataApi
        .zbglGetList()
        .then((response) => {
          if (response.code == 200) {
            this.basicInformation = response.result;
            this.configurationInformation = response.result;
            this.parameterForm = JSON.parse(
              JSON.stringify(this.configurationInformation)
            );
            this.detailsDialog = true;
          } else {
            this.$message({
              message: response.message,
              type: "error",
            });
          }
        })
        .catch(() => {});
    },
    // 监测站固态存储数据
    goJczData() {
      this.jczTableData = [];
      this.jczDataDialog = true;
    },
    //基础配置提交
    confirmConfiguration() {
      // CForm
      console.log(this.upConfiguration, 789987);
      return;
      basicDataApi
        .cpxqUpdateDeviceConfigInfo(this.upConfiguration)
        .then((response) => {
          if (response.code == 200) {
            this.$message.success("设置成功");
            this.configurationDialog = false;
            this.basicInformation = JSON.parse(
              JSON.stringify(this.upConfiguration)
            );
          } else {
            this.$message.error("设置失败");
          }
        });

      // this.$message.success("设置成功");
      // this.dialogVisible = false
    },
    //监测站固态存储数据转存历史数据
    handleClick() {
      this.$message.success("转存成功");
    },
    //阀门急停
    stopGate() {
      this.$confirm(`确定要紧急停止阀门吗?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        cancelButtonClass: "message-style",
        type: "warning",
      })
        .then(() => {
          let subData = {
            deviceNo: this.basicInformation.DeviceNo,
            cmd: "444",
            type: 2,
            value: "",
            pwd: "",
          };
          basicDataApi.sbxqSendCommand(subData).then((res) => {
            if(res.code ==200&&res.result.executeState==1){
              this.$message.success('急停指令下发成功')
            }else{
              this.$message.error('急停指令下发失败')
            }
          }).catch(()=>{
            this.$message.error('急停指令下发失败')
          })
          // basicDataApi
          //   .cpxqStopGate({ deviceNo: this.basicInformation.DeviceNo })
          //   .then((response) => {
          //     if (response.code == 200) {
          //       this.$message.success(response.result.remark);
          //     } else {
          //       this.$message.error("急停失败");
          //     }
          //   });
          //
        })
        .catch(() => {});
    },
    // 清空监测站历史数据
    clearHistory() {
      this.$confirm(`确定要清空监测站历史数据单元吗?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        cancelButtonClass: "message-style",
        type: "warning",
      })
        .then(() => {
          let subData = {
            deviceNo: this.basicInformation.DeviceNo,
            cmd: "145",
            type: 2,
            value: "",
            pwd: "",
          };
          basicDataApi.sbxqSendCommand(subData).then((res) => {
            if(res.code ==200&&res.result.executeState==1){
              this.$message.success('清空指令下发成功')
            }else{
              this.$message.error('清空指令下发失败')
            }
          }).catch(()=>{
            this.$message.error('清空指令下发失败')
          })
          // basicDataApi
          //   .cpxqClearHistoryData({ deviceNo: this.basicInformation.DeviceNo })
          //   .then((response) => {
          //     if (response.code == 200) {
          //       this.$message.success("清空成功");
          //     } else {
          //       this.$message.error("清空失败");
          //     }
          //   });
        })
        .catch(() => {});
    },
    // 闸门控制提交
    onControlSubmit(formName) { 
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let subData = {
            DeviceId: this.basicInformation.Id,
            DeviceNo: this.basicInformation.DeviceNo,
          };
          this.controlForm.DeliveryChannel  ="2"
          basicDataApi
            .cpxqHSDevicePlansAdd(Object.assign(subData, this.controlForm))
            .then((response) => {
              if (response.code == 200) {
                this.$message.success("添加成功");
                this.getList();
                this.clearControlForm("controlForm");
              } else {
                this.$message.error("添加失败");
              }
            })
            .catch(() => {});

          // alert('submit!');
          // this.$message.success('提交成功');
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //清空控制表单
    clearControlForm(formName) {
      this.controlForm.executeStartTime = "";
      this.controlForm.executeEndTime = "";
      this.$refs[formName].resetFields();
    },
    //获取计划table数据
    getListPlanTable() {
      return;
      this.loading = true;
      basicDataApi
        .cbxxGetList(this.listQueryPlan)
        .then((response) => {
          if (response.code == 200) {
            response.columnHeaders.forEach((item) => {
              // 首字母小写
              item.key =
                item.key.substring(0, 1).toLowerCase() + item.key.substring(1);
            });
            this.tableDataPlan = response.data;
            this.tableTotalPlan = response.count;
          } else {
            this.tableDataPlan = [];
            this.$notify({
              message: response.message,
              type: "warning",
              duration: 2000,
            });
          }
          this.loading = false;
        })
        .catch(() => {
          this.tableDataPlan = [];
          this.loading = false;
        });
    },
    //删除计划
    deletePlan(rowData) {
      this.$confirm(`确定要删除计划吗?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        cancelButtonClass: "message-style",
        type: "warning",
      })
        .then(() => {
          basicDataApi
            .cpxqHSDevicePlansDelete([rowData.id])
            .then((response) => {
              if (response.code == 200) {
                this.$message.success("删除成功");
                this.getList();
              } else {
                this.$message.error("删除失败");
              }
            });
        })
        .catch(() => {});
    },
    // 切换分页
    handleCurrentChanges(val) {
      this.listQuery.page = val.page;
      this.listQuery.limit = val.limit;
      this.getHistoryData();
    },
    // 切换分页
    handleCurrentChange(val) {
      this.listQueryPlan.page = val.page;
      this.listQueryPlan.limit = val.limit;
      this.getList();
    },
    // 实时水位曲线图
    getDailyChart(xDatas, d1) {
      var data = {
        isTotalMeter: this.isTotalMeter,
      };
      // basicDataApi.homeGetRealChart(data).then(response => {
      // if(response.code == 200){
      // xDatas = response.result.xDatas
      // d1 = response.result.y1Datas
      // d2 = response.result.y2Datas
      // xDatas = ['2023-12-13','2023-12-14','2023-12-15','2023-12-16','2023-12-17','2023-12-18','2023-12-19','2023-12-13','2023-12-14','2023-12-15','2023-12-16','2023-12-17','2023-12-18','2023-12-19','2023-12-13','2023-12-14','2023-12-15','2023-12-16','2023-12-17','2023-12-18','2023-12-19','2023-12-13','2023-12-14','2023-12-15','2023-12-16','2023-12-17','2023-12-18','2023-12-19',]
      // d1 = ['10','8','14','22','5','11','14','20','28','34','32','25','31','34','14','22','5','11','14','20','28','34','32','25','31','34','14','22',]
      // 最近30分钟访问情况

      this.RealTimeWaterLevel = echarts.init(
        document.getElementById("RealTimeWaterLevel")
      );
      this.RealTimeWaterLevel.setOption({
        // title: {
        //   text: '实时水位曲线图'
        // },
        tooltip: {
          trigger: "axis",
        },
        grid: {
          //图表位置
          top: "15%",
          bottom: "10%",
          right: "10%",
        },
        legend: {
          data: ["实时水位"],
          x: "right", //可设定图例在左、右、居中
          y: "top", //可设定图例在上、下、居中
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false, //坐标轴两边留白
            axisTick: { show: false },
            data: xDatas,
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [
          {
            name: "实时水位",
            type: "line",
            itemStyle: {
              color: "#c09b31",
            },
            lineStyle: {
              color: "#c09b31",
              width: 1,
            },
            // areaStyle: {
            //   color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
            //     { offset: 0, color: "rgba(192,155,49,0.3)" },
            //     { offset: 1, color: "rgba(192,155,49,1)" },
            //   ]),
            // },
            stack: "Total",
            data: d1,
            smooth: true,
          },
          // {
          //   name:'未抄表数',
          //   type: 'line',
          //   itemStyle: {
          //     color:'#b694f0',
          //   },
          //   lineStyle: {
          //     color: "#b694f0",
          //     width:1
          //   },
          //   areaStyle: {
          //     color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [{offset: 0,color: 'rgba(182,148,240,0.3)'},{offset: 1,color: 'rgba(182,148,240,1)'}]),
          //   },
          //   stack: 'Total',
          //   data: d2,
          //   smooth: true
          // },
        ],
      });
      //   }else{
      //     xDatas = ''
      //     d1 = ''
      //     d2 = ''
      //     this.$notify({
      //       message: response.message,
      //       type: 'warning',
      //       duration: 2000
      //     });
      //   }
      // }).catch(()=>{})
    },
    // 实时流量曲线图
    getMonthlyChart(xDatas, d1) {
      var data = {
        isTotalMeter: this.isTotalMeter,
      };
      // basicDataApi.homeGetRealChart(data).then(response => {
      // if(response.code == 200){
      // xDatas = response.result.xDatas
      // d1 = response.result.y1Datas
      // d2 = response.result.y2Datas
      // xDatas = ['2023-12-13','2023-12-14','2023-12-15','2023-12-16','2023-12-17','2023-12-18','2023-12-19','2023-12-13','2023-12-14','2023-12-15','2023-12-16','2023-12-17','2023-12-18','2023-12-19','2023-12-13','2023-12-14','2023-12-15','2023-12-16','2023-12-17','2023-12-18','2023-12-19','2023-12-13','2023-12-14','2023-12-15','2023-12-16','2023-12-17','2023-12-18','2023-12-19',]
      // d1 = ['20','28','34','32','25','31','34','10','8','14','22','5','11','14','34','32','25','31','34','10','8','8','14','22','5','11','14','34',]
      // d2 = ['10','8','14','22','5','11','14',]
      // 最近30分钟访问情况
      this.RealTimeTraffic = echarts.init(
        document.getElementById("RealTimeTraffic")
      );
      this.RealTimeTraffic.setOption({
        // title: {
        //   text: '实时流量曲线图'
        // },
        tooltip: {
          trigger: "axis",
        },
        grid: {
          //图表位置
          top: "15%",
          bottom: "10%",
          right: "10%",
        },
        legend: {
          data: ["实时流量"],
          x: "right", //可设定图例在左、右、居中
          y: "top", //可设定图例在上、下、居中
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false, //坐标轴两边留白
            axisTick: { show: false },
            data: xDatas,
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [
          {
            name: "实时流量",
            type: "line",
            itemStyle: {
              color: "#1a96d4",
            },
            lineStyle: {
              color: "#1a96d4",
              width: 1,
            },
            // areaStyle: {
            //   color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
            //     { offset: 0, color: "rgba(26, 150, 212,0.3)" },
            //     { offset: 1, color: "rgba(26, 150, 212,1)" },
            //   ]),
            // },
            stack: "Total",
            data: d1,
            smooth: true,
          },
          // {
          //   name:'未抄表数',
          //   type: 'line',
          //   itemStyle: {
          //     color:'#b694f0',
          //   },
          //   lineStyle: {
          //     color: "#b694f0",
          //     width:1
          //   },
          //   areaStyle: {
          //     color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [{offset: 0,color: 'rgba(182,148,240,0.3)'},{offset: 1,color: 'rgba(182,148,240,1)'}]),
          //   },
          //   stack: 'Total',
          //   data: d2,
          //   smooth: true
          // },
        ],
      });
      //   }else{
      //     xDatas = ''
      //     d1 = ''
      //     d2 = ''
      //     this.$notify({
      //       message: response.message,
      //       type: 'warning',
      //       duration: 2000
      //     });
      //   }
      // }).catch(()=>{})
    },
  },
};
</script>

<style lang="scss" scoped>
.pane_container {
  background: #fff;
  padding: 0 10px 10px 10px;
  .row_css {
    height: calc((100vh - 45px - 95px) / 2);
    overflow: hidden;
  }
  .bottom_row {
    .el-col {
      padding: 0 !important;
    }
    .bottom_row_border {
      height: calc((100vh - 45px - 95px) / 2 - 10px);
      border: 2px solid #1a96d4;
      border-radius: 10px;
      padding: 5px;
    }
  }
  .box_description {
    .box_span {
      line-height: 24px;
      font-size: 14px;
      color: #1890ff;
      border-bottom: 2px solid #1890ff;
    }
    .box_span_img {
      width: 24px;
      height: 24px;
      vertical-align: bottom;
      margin-right: 5px;
    }
  }
  .box_content {
    .row_video_css {
      height: calc((100vh - 45px - 35px - 10px - 50px - 30px) / 2 - 40px);
    }
    .box_content_row {
      height: calc((100vh - 45px - 35px - 10px - 50px - 20px) / 2 - 75px);
      overflow-y: scroll;
      scrollbar-width: none; /* firefox */
      -ms-overflow-style: none; /* IE 10+ */
      overflow-x: hidden;
      overflow-y: auto;
    }
    .box_content_row::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }

    // 描述列表超长隐藏
    ::v-deep .el-descriptions-item__container .el-descriptions-item__content{
      text-overflow: ellipsis;
      display: inline-block;
    }
    .box_content_row ::v-deep .el-descriptions__body .el-descriptions__table .el-descriptions-item__cell{padding-bottom: 3px;}
    .box_content_row ::v-deep .el-descriptions :not(.is-bordered) .el-descriptions-item__cell{padding-bottom: 3px;}
    .box_content_bottom {
      text-align: right;
    }
  }
  .controlForm_box {
    height: calc((100vh - 45px - 35px - 10px - 50px - 20px) / 2 - 75px);
    overflow-y: scroll;
    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE 10+ */
    overflow-x: hidden;
    overflow-y: auto;
    .controlForm {
      width: 80%;
      margin: 0 auto;
      // .el-form-item{
      //   margin-bottom: 5px;
      // }
    }
    ::v-deep .el-form-item{
      margin-bottom: 5px;
      line-height: 33px;
    }
    ::v-deep .el-form-item .el-input__inner{
      height: 30px;
      line-height: 30px;
    }
    ::v-deep .el-form-item .el-form-item__label,::v-deep .el-form-item .el-form-item__content{
      line-height: 33px;
    }
    
  }
  .controlForm_box::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
}
.parameterForm_css {
  padding: 0 5%;
  .el-form-item {
    width: 50%;
    float: left;
  }
}
::v-deep .el-descriptions-item__container .el-descriptions-item__content {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
}

.device_description{
  color:red;
}
.showDetails ::v-deep .el-descriptions-item__container .el-descriptions-item__content{
  text-overflow: ellipsis;
  display: inline-block;
}
.red_font{
  font-weight: bold;
  color:red;
  font-size: 16px;
}
</style>
