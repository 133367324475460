var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pane_container", attrs: { id: "pane_container" } },
    [
      _c(
        "el-row",
        {
          staticClass: "row_css",
          staticStyle: {
            border: "2px solid #1a96d4",
            "border-radius": "10px",
            padding: "5px",
          },
          attrs: { gutter: 16 },
        },
        [
          _c("el-col", { attrs: { span: 4 } }, [
            _c(
              "div",
              {
                staticClass: "box_description",
                staticStyle: { "margin-bottom": "5px" },
              },
              [
                _c("img", {
                  staticClass: "box_span_img",
                  attrs: { src: require("@/assets/img/基本信息.png"), alt: "" },
                }),
                _c(
                  "span",
                  {
                    staticClass: "box_span",
                    staticStyle: { "margin-right": "20px" },
                  },
                  [_vm._v("基本信息")]
                ),
                _vm.basicInformation.Status == 1
                  ? _c("el-tag", { attrs: { type: "success", size: "mini" } }, [
                      _vm._v("在线"),
                    ])
                  : _c("el-tag", { attrs: { type: "info", size: "mini" } }, [
                      _vm._v("在装"),
                    ]),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "box_content" },
              [
                _c(
                  "el-row",
                  { staticClass: "box_content_row" },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        this.basicInformation.DeviceTypeId == "HS_CKYTHZM"
                          ? [
                              _c(
                                "el-descriptions",
                                {
                                  staticClass: "left_descriptions",
                                  attrs: { column: 1 },
                                },
                                [
                                  _c(
                                    "el-descriptions-item",
                                    { attrs: { label: "安装地点" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.basicInformation.InstalledLocation
                                        )
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "el-descriptions-item",
                                    { attrs: { label: "控制模式" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.basicInformation.ControlMode ==
                                            "1"
                                            ? "流量"
                                            : "闸位"
                                        )
                                      ),
                                    ]
                                  ),
                                  _vm.basicInformation.ControlMode == "1"
                                    ? _c(
                                        "el-descriptions-item",
                                        { attrs: { label: "目标流量(m³/s)" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.basicInformation
                                                .FlowControlSettingValue
                                            )
                                          ),
                                        ]
                                      )
                                    : _c(
                                        "el-descriptions-item",
                                        { attrs: { label: "目标闸位(m)" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.basicInformation
                                                .GateControlSettingValue
                                            )
                                          ),
                                        ]
                                      ),
                                  _c(
                                    "el-descriptions-item",
                                    { attrs: { label: "操作人员" } },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.basicInformation.Operator)
                                      ),
                                    ]
                                  ),
                                  _vm.basicInformation.DeliveryChannel == "0"
                                    ? _c(
                                        "el-descriptions-item",
                                        { attrs: { label: "下发渠道" } },
                                        [_vm._v("未知")]
                                      )
                                    : _vm._e(),
                                  _vm.basicInformation.DeliveryChannel == "1"
                                    ? _c(
                                        "el-descriptions-item",
                                        { attrs: { label: "下发渠道" } },
                                        [_vm._v("本地")]
                                      )
                                    : _vm._e(),
                                  _vm.basicInformation.DeliveryChannel == "2"
                                    ? _c(
                                        "el-descriptions-item",
                                        { attrs: { label: "下发渠道" } },
                                        [_vm._v("网页")]
                                      )
                                    : _vm._e(),
                                  _vm.basicInformation.DeliveryChannel == "3"
                                    ? _c(
                                        "el-descriptions-item",
                                        { attrs: { label: "下发渠道" } },
                                        [_vm._v("小程序")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          : [
                              _c(
                                "el-descriptions",
                                {
                                  staticClass: "left_descriptions",
                                  attrs: { column: 1 },
                                },
                                [
                                  _c(
                                    "el-descriptions-item",
                                    { attrs: { label: "设备编号" } },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.basicInformation.DeviceNo)
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "el-descriptions-item",
                                    { attrs: { label: "软件版本号" } },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.basicInformation.VersionNo)
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "el-descriptions-item",
                                    { attrs: { label: "安装地点" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.basicInformation.InstalledLocation
                                        )
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "el-descriptions-item",
                                    { attrs: { label: "安装时间" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.basicInformation.InstalledTime
                                        )
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "el-descriptions-item",
                                    { attrs: { label: "经度坐标" } },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.basicInformation.Longitude)
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "el-descriptions-item",
                                    { attrs: { label: "纬度坐标" } },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.basicInformation.Latitude)
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "el-descriptions-item",
                                    { attrs: { label: "用水定额(m³)" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.basicInformation
                                            .WaterConsumptionNorm
                                        )
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "el-descriptions-item",
                                    { attrs: { label: "灌溉亩数(亩)" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.basicInformation.IrrigatedAcreage
                                        )
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "el-descriptions-item",
                                    { attrs: { label: "作物类型" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.basicInformation.CropTypeName
                                        )
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "el-descriptions-item",
                                    { attrs: { label: "分支机构" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.basicInformation.BelongAreaName
                                        )
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "el-descriptions-item",
                                    { attrs: { label: "设备描述" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.basicInformation.DeviceRemark
                                        )
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                      ],
                      2
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "box_content_bottom" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "success", plain: "", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.refreshData()
                          },
                        },
                      },
                      [_vm._v("刷新")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.goCheck(1)
                          },
                        },
                      },
                      [_vm._v("查看明细")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "success", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.openConDialog()
                          },
                        },
                      },
                      [_vm._v("配置信息")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c("el-col", { attrs: { span: 10 } }, [
            _c("div", { staticClass: "box_content" }, [
              _c(
                "div",
                {
                  staticStyle: {
                    width: "100%",
                    "background-repeat": "no-repeat",
                    "background-size": "80%",
                    "background-position": "50% 20px",
                    height: "400px",
                  },
                  style: {
                    backgroundImage: "url(" + _vm.backgroundImage + ")",
                  },
                },
                [
                  _c(
                    "el-row",
                    { staticStyle: { "font-size": "14px", color: "#606266" } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _vm.basicInformation.Icon == 1 ||
                          _vm.basicInformation.Icon == 2 ||
                          _vm.basicInformation.Icon == 3 ||
                          _vm.basicInformation.Icon == 4 ||
                          _vm.basicInformation.Icon == 5 ||
                          _vm.basicInformation.Icon == 6 ||
                          _vm.basicInformation.Icon == 7
                            ? [
                                _c("el-col", {
                                  staticStyle: {
                                    border: "1px solid transparent",
                                  },
                                  attrs: { span: 11 },
                                }),
                                _c(
                                  "el-col",
                                  { attrs: { span: 13 } },
                                  [
                                    _c("el-col", { attrs: { span: 24 } }, [
                                      _vm._v(" 当前闸位: "),
                                      _c("span", { staticClass: "red_font" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.basicInformation.GatePosition
                                            ) +
                                            " "
                                        ),
                                      ]),
                                      _vm._v(" (m) "),
                                    ]),
                                  ],
                                  1
                                ),
                              ]
                            : [
                                _vm.basicInformation.DeviceTypeId == "HS_SWJ"
                                  ? [
                                      _c(
                                        "el-col",
                                        {
                                          staticStyle: {
                                            "text-align": "center",
                                          },
                                          attrs: { span: 24 },
                                        },
                                        [
                                          _vm._v(" 水位计水位: "),
                                          _c(
                                            "span",
                                            { staticClass: "red_font" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.basicInformation
                                                      .WaterLevel_SWJ
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" (m) "),
                                        ]
                                      ),
                                    ]
                                  : [
                                      _c(
                                        "el-col",
                                        {
                                          staticStyle: {
                                            "text-align": "center",
                                          },
                                          attrs: { span: 24 },
                                        },
                                        [
                                          _vm._v(" 流量计水位: "),
                                          _c(
                                            "span",
                                            { staticClass: "red_font" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.basicInformation
                                                      .WaterLevel_LLJ
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" (m)     流量计水深: "),
                                          _c(
                                            "span",
                                            { staticClass: "red_font" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.basicInformation
                                                      .WaterDepth
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" (m) "),
                                          _c("br"),
                                          _vm._v(" 瞬时流量: "),
                                          _c(
                                            "span",
                                            { staticClass: "red_font" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.basicInformation
                                                      .FlowRate
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" (m³/s)     累计水量: "),
                                          _c(
                                            "span",
                                            { staticClass: "red_font" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.basicInformation
                                                      .TotalFlow
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" (m³) "),
                                        ]
                                      ),
                                    ],
                              ],
                        ],
                        2
                      ),
                      _vm.basicInformation.Icon == 1 ||
                      _vm.basicInformation.Icon == 2 ||
                      _vm.basicInformation.Icon == 3 ||
                      _vm.basicInformation.Icon == 4 ||
                      _vm.basicInformation.Icon == 5 ||
                      _vm.basicInformation.Icon == 6 ||
                      _vm.basicInformation.Icon == 7
                        ? _c(
                            "div",
                            { staticStyle: { position: "relative" } },
                            [
                              _c(
                                "el-col",
                                {
                                  staticStyle: {
                                    position: "relative",
                                    "text-align": "right",
                                    top: "calc((100vh - 45px - 35px - 10px - 50px - 20px) / 2 - 215px)",
                                  },
                                  attrs: { span: 6 },
                                },
                                [
                                  _vm.basicInformation.Icon == 1 ||
                                  _vm.basicInformation.Icon == 7
                                    ? [
                                        _c("el-col", { attrs: { span: 24 } }, [
                                          _vm._v(" 流量计水位: "),
                                          _c(
                                            "span",
                                            { staticClass: "red_font" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.basicInformation
                                                      .WaterLevel_LLJ
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" (m) "),
                                        ]),
                                        _c("el-col", { attrs: { span: 24 } }, [
                                          _vm._v(" 流量计水深: "),
                                          _c(
                                            "span",
                                            { staticClass: "red_font" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.basicInformation
                                                      .WaterDepth
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" (m) "),
                                        ]),
                                        _c("el-col", { attrs: { span: 24 } }, [
                                          _vm._v(" 瞬时流量: "),
                                          _c(
                                            "span",
                                            { staticClass: "red_font" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.basicInformation
                                                      .FlowRate
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" (m³/s) "),
                                        ]),
                                        _c("el-col", { attrs: { span: 24 } }, [
                                          _vm._v(" 累计水量: "),
                                          _c(
                                            "span",
                                            { staticClass: "red_font" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.basicInformation
                                                      .TotalFlow
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" (m³) "),
                                        ]),
                                      ]
                                    : [
                                        _c("el-col", { attrs: { span: 24 } }, [
                                          _vm._v(" 水位计水位: "),
                                          _c(
                                            "span",
                                            { staticClass: "red_font" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.basicInformation
                                                      .WaterLevel_SWJ
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" (m) "),
                                        ]),
                                      ],
                                ],
                                2
                              ),
                              _c("el-col", {
                                staticStyle: {
                                  border: "1px solid transparent",
                                },
                                attrs: { span: 12 },
                              }),
                              _c(
                                "el-col",
                                {
                                  staticStyle: { "text-align": "left" },
                                  attrs: { span: 6 },
                                },
                                [
                                  _vm.basicInformation.Icon != 1 &&
                                  _vm.basicInformation.Icon != 7
                                    ? [
                                        _c("el-col", { attrs: { span: 24 } }, [
                                          _vm._v(" 流量计水位: "),
                                          _c(
                                            "span",
                                            { staticClass: "red_font" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.basicInformation
                                                      .WaterLevel_LLJ
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" (m) "),
                                        ]),
                                        _c("el-col", { attrs: { span: 24 } }, [
                                          _vm._v(" 流量计水深: "),
                                          _c(
                                            "span",
                                            { staticClass: "red_font" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.basicInformation
                                                      .WaterDepth
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" (m) "),
                                        ]),
                                        _c("el-col", { attrs: { span: 24 } }, [
                                          _vm._v(" 瞬时流量: "),
                                          _c(
                                            "span",
                                            { staticClass: "red_font" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.basicInformation
                                                      .FlowRate
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" (m³/s) "),
                                        ]),
                                        _c("el-col", { attrs: { span: 24 } }, [
                                          _vm._v(" 累计水量: "),
                                          _c(
                                            "span",
                                            { staticClass: "red_font" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.basicInformation
                                                      .TotalFlow
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" (m³) "),
                                        ]),
                                      ]
                                    : [
                                        _c("el-col", { attrs: { span: 24 } }, [
                                          _vm._v(" 水位计水位: "),
                                          _c(
                                            "span",
                                            { staticClass: "red_font" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.basicInformation
                                                      .WaterLevel_SWJ
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" (m) "),
                                        ]),
                                      ],
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
          _c("el-col", { attrs: { span: 10 } }, [
            _c(
              "div",
              {
                staticClass: "box_description",
                staticStyle: { "margin-bottom": "5px" },
              },
              [
                _c("img", {
                  staticClass: "box_span_img",
                  attrs: { src: require("@/assets/img/监控.png"), alt: "" },
                }),
                _c("span", { staticClass: "box_span" }, [_vm._v("设备监控")]),
              ]
            ),
            _c("div", { staticClass: "box_content" }, [
              _c("iframe", {
                staticClass: "row_video_css",
                staticStyle: { width: "100%", "margin-bottom": "10px" },
                attrs: { src: _vm.videoUrl },
              }),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "el-row",
        {
          staticClass: "row_css bottom_row",
          staticStyle: { "margin-top": "10px" },
          attrs: { gutter: 16 },
        },
        [
          _c("el-col", { attrs: { span: 2 } }, [
            _c(
              "div",
              { staticClass: "bottom_row_border" },
              [
                _vm.basicInformation.DeviceTypeId == "HS_CKYTHZM"
                  ? [
                      _c(
                        "div",
                        {
                          staticClass: "box_description",
                          staticStyle: { "margin-bottom": "5px" },
                        },
                        [
                          _c("img", {
                            staticClass: "box_span_img",
                            attrs: {
                              src: require("@/assets/img/急停.png"),
                              alt: "",
                            },
                          }),
                          _c("span", { staticClass: "box_span" }, [
                            _vm._v(" 闸门急停"),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "box_content",
                          staticStyle: {
                            padding: "20px",
                            "text-align": "center",
                          },
                        },
                        [
                          _c("el-image", {
                            staticStyle: {
                              width: "80%",
                              margin: "0px 0",
                              cursor: "pointer",
                            },
                            attrs: { src: require("@/assets/img/stop.png") },
                            on: { click: _vm.stopGate },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "box_description",
                          staticStyle: { "margin-bottom": "5px" },
                        },
                        [
                          _c("img", {
                            staticClass: "box_span_img",
                            attrs: {
                              src: require("@/assets/img/操作.png"),
                              alt: "",
                            },
                          }),
                          _c("span", { staticClass: "box_span" }, [
                            _vm._v(" 监测站操作 "),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        { staticStyle: { "text-align": "center" } },
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: {
                                "margin-top": "20%",
                                width: "90%",
                                "white-space": "normal",
                              },
                              attrs: { type: "warning", size: "mini" },
                              on: {
                                click: function ($event) {
                                  return _vm.goJczData()
                                },
                              },
                            },
                            [_vm._v("固态存储数据")]
                          ),
                          _c(
                            "el-button",
                            {
                              staticStyle: {
                                "margin-top": "20%",
                                "margin-left": "0",
                                width: "90%",
                                "white-space": "normal",
                              },
                              attrs: { type: "danger", size: "mini" },
                              on: {
                                click: function ($event) {
                                  return _vm.clearHistory()
                                },
                              },
                            },
                            [_vm._v("清空历史数据")]
                          ),
                        ],
                        1
                      ),
                    ]
                  : [
                      _c(
                        "div",
                        {
                          staticClass: "box_description",
                          staticStyle: { "margin-bottom": "5px" },
                        },
                        [
                          _c("img", {
                            staticClass: "box_span_img",
                            attrs: {
                              src: require("@/assets/img/操作.png"),
                              alt: "",
                            },
                          }),
                          _c("span", { staticClass: "box_span" }, [
                            _vm._v(" 监测站操作 "),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "text-align": "center",
                            "margin-top": "20%",
                          },
                        },
                        [
                          _c("el-image", {
                            staticStyle: {
                              width: "40%",
                              margin: "10px auto",
                              cursor: "pointer",
                            },
                            attrs: {
                              title: "监测站固态存储数据",
                              src: require("@/assets/img/jiance.png"),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.goJczData()
                              },
                            },
                          }),
                          _c(
                            "p",
                            {
                              staticStyle: {
                                "font-size": "12px",
                                color: "#1890ff",
                                margin: "5px",
                              },
                            },
                            [_vm._v("固态存储数据")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "text-align": "center",
                            "margin-top": "20%",
                          },
                        },
                        [
                          _c("el-image", {
                            staticStyle: {
                              width: "40%",
                              margin: "10px auto",
                              cursor: "pointer",
                            },
                            attrs: {
                              title: "清空监测站历史数据",
                              src: require("@/assets/img/clear.png"),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.clearHistory()
                              },
                            },
                          }),
                          _c(
                            "p",
                            {
                              staticStyle: {
                                "font-size": "12px",
                                color: "#1890ff",
                                margin: "5px",
                              },
                            },
                            [_vm._v("清空历史数据")]
                          ),
                        ],
                        1
                      ),
                    ],
              ],
              2
            ),
          ]),
          _vm.basicInformation.DeviceTypeId == "HS_CKYTHZM"
            ? [
                _c("el-col", { attrs: { span: 9 } }, [
                  _c(
                    "div",
                    {
                      staticClass: "bottom_row_border",
                      staticStyle: { margin: "0 4px" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "box_description",
                          staticStyle: { "margin-bottom": "5px" },
                        },
                        [
                          _c("img", {
                            staticClass: "box_span_img",
                            attrs: {
                              src: require("@/assets/img/控制.png"),
                              alt: "",
                            },
                          }),
                          _c("span", { staticClass: "box_span" }, [
                            _vm._v("闸门控制"),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "box_content controlForm_box" },
                        [
                          _c(
                            "el-form",
                            {
                              ref: "controlForm",
                              staticClass: "controlForm",
                              attrs: {
                                model: _vm.controlForm,
                                rules: _vm.ontrolRules,
                                "label-width": "130px",
                              },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "计划名称",
                                    prop: "PlanName",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.controlForm.PlanName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.controlForm,
                                          "PlanName",
                                          $$v
                                        )
                                      },
                                      expression: "controlForm.PlanName",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "控制模式",
                                    prop: "ControlMode",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: { placeholder: "控制模式" },
                                      model: {
                                        value: _vm.controlForm.ControlMode,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.controlForm,
                                            "ControlMode",
                                            $$v
                                          )
                                        },
                                        expression: "controlForm.ControlMode",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: { label: "流量", value: "1" },
                                      }),
                                      _c("el-option", {
                                        attrs: { label: "闸位", value: "2" },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm.controlForm.ControlMode == "1"
                                ? _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "控制参数(m³/s)",
                                        prop: "controlParam",
                                      },
                                    },
                                    [
                                      _c("el-input-number", {
                                        staticClass: "special_i",
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          size: "mini",
                                          min: -999999.999,
                                          max: 999999.999,
                                        },
                                        model: {
                                          value: _vm.controlForm.controlParam,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.controlForm,
                                              "controlParam",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "controlForm.controlParam",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.controlForm.ControlMode == "2"
                                ? _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "控制参数(m)",
                                        prop: "controlParam",
                                      },
                                    },
                                    [
                                      _c("el-input-number", {
                                        staticClass: "special_i",
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          size: "mini",
                                          min: 0,
                                          max: 99.999,
                                        },
                                        model: {
                                          value: _vm.controlForm.controlParam,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.controlForm,
                                              "controlParam",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "controlForm.controlParam",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "el-form-item",
                                { attrs: { label: "立即执行" } },
                                [
                                  _c("el-switch", {
                                    on: { change: _vm.chExecuteImmediately },
                                    model: {
                                      value: _vm.controlForm.ExecuteImmediately,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.controlForm,
                                          "ExecuteImmediately",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "controlForm.ExecuteImmediately",
                                    },
                                  }),
                                ],
                                1
                              ),
                              !_vm.controlForm.ExecuteImmediately
                                ? _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "开始时间",
                                        prop: "executeStartTime",
                                      },
                                    },
                                    [
                                      _c("el-date-picker", {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          type: "datetime",
                                          placeholder: "选择开始时间",
                                          "value-format": "yyyy-MM-dd HH:mm:ss",
                                        },
                                        model: {
                                          value:
                                            _vm.controlForm.executeStartTime,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.controlForm,
                                              "executeStartTime",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "controlForm.executeStartTime",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              !_vm.controlForm.ExecuteImmediately
                                ? _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "结束时间",
                                        prop: "executeEndTime",
                                      },
                                    },
                                    [
                                      _c("el-date-picker", {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          type: "datetime",
                                          placeholder: "选择结束时间",
                                          "value-format": "yyyy-MM-dd HH:mm:ss",
                                        },
                                        model: {
                                          value: _vm.controlForm.executeEndTime,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.controlForm,
                                              "executeEndTime",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "controlForm.executeEndTime",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "开闸密码",
                                    prop: "openGatePwd",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: { maxlength: 4 },
                                    on: { input: _vm.handleInputPwds },
                                    model: {
                                      value: _vm.controlForm.openGatePwd,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.controlForm,
                                          "openGatePwd",
                                          $$v
                                        )
                                      },
                                      expression: "controlForm.openGatePwd",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { "text-align": "right" } },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "warning", size: "mini" },
                              on: {
                                click: function ($event) {
                                  return _vm.clearControlForm("controlForm")
                                },
                              },
                            },
                            [_vm._v("清空")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { size: "mini", type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.onControlSubmit("controlForm")
                                },
                              },
                            },
                            [_vm._v("提交")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ]),
                _c("el-col", { attrs: { span: 13 } }, [
                  _c(
                    "div",
                    {
                      staticClass: "bottom_row_border",
                      staticStyle: { "z-index": "999" },
                      attrs: { id: "table_box_height" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "box_description",
                          staticStyle: { "margin-bottom": "5px" },
                        },
                        [
                          _c("img", {
                            staticClass: "box_span_img",
                            attrs: {
                              src: require("@/assets/img/计划.png"),
                              alt: "",
                            },
                          }),
                          _c("span", { staticClass: "box_span" }, [
                            _vm._v("计划控制列表"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "box_span",
                              staticStyle: {
                                display: "inline-block",
                                position: "absolute",
                                right: "20px",
                                top: "5px",
                                cursor: "pointer",
                              },
                              on: { click: _vm.getList },
                            },
                            [_vm._v("刷新")]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "box_content",
                          attrs: { id: "table_box" },
                        },
                        [
                          _c("basic-table", {
                            attrs: {
                              tableHeight: _vm.tableHeightPlan,
                              tableHeaderList: _vm.firstHeaderListPlan,
                              tableData: _vm.tableDataPlan,
                              listQuery: _vm.listQueryPlan,
                              tableTotal: _vm.tableTotalPlan,
                              hasIndex: true,
                            },
                            on: { pagination: _vm.handleCurrentChange },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ]),
              ]
            : [
                _c("el-col", { attrs: { span: 11 } }, [
                  _c(
                    "div",
                    {
                      staticClass: "bottom_row_border",
                      staticStyle: { margin: "0 4px" },
                      attrs: { id: "echarts_box_height" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "box_description",
                          staticStyle: { "margin-bottom": "5px" },
                        },
                        [
                          _c("img", {
                            staticClass: "box_span_img",
                            attrs: {
                              src: require("@/assets/img/曲线图.png"),
                              alt: "",
                            },
                          }),
                          _c("span", { staticClass: "box_span" }, [
                            _vm._v("实时水位曲线图"),
                          ]),
                        ]
                      ),
                      _c("div", {
                        style: {
                          width: "100%",
                          height: _vm.chartHeight + "px",
                        },
                        attrs: { id: "RealTimeWaterLevel" },
                      }),
                    ]
                  ),
                ]),
                _c("el-col", { attrs: { span: 11 } }, [
                  _c("div", { staticClass: "bottom_row_border" }, [
                    _c(
                      "div",
                      {
                        staticClass: "box_description",
                        staticStyle: { "margin-bottom": "5px" },
                      },
                      [
                        _c("img", {
                          staticClass: "box_span_img",
                          attrs: {
                            src: require("@/assets/img/曲线图.png"),
                            alt: "",
                          },
                        }),
                        _c("span", { staticClass: "box_span" }, [
                          _vm._v("实时流量曲线图"),
                        ]),
                      ]
                    ),
                    _c("div", {
                      style: { width: "100%", height: _vm.chartHeight + "px" },
                      attrs: { id: "RealTimeTraffic" },
                    }),
                  ]),
                ]),
              ],
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          staticClass: "showDetails",
          attrs: {
            title: "查看明细",
            visible: _vm.detailsDialog,
            width: "70%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.detailsDialog = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c("el-divider", { attrs: { "content-position": "left" } }, [
                _c("span", { staticStyle: { color: "#1a96d4" } }, [
                  _vm._v("实时数据"),
                ]),
              ]),
              _c(
                "el-descriptions",
                { staticClass: "left_descriptions", attrs: { column: 3 } },
                [
                  _vm.basicInformation.DeviceTypeId == "HS_CKYTHZM"
                    ? [
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "流量计水位 (m)" } },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.basicInformation.WaterLevel_LLJ)
                            ),
                          ]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "流量计水深 (m)" } },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.basicInformation.WaterDepth)
                            ),
                          ]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "水位计水位 (m)" } },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.basicInformation.WaterLevel_SWJ)
                            ),
                          ]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "瞬时流量(m³/s)" } },
                          [_vm._v(" " + _vm._s(_vm.basicInformation.FlowRate))]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "累计流量(m³)" } },
                          [_vm._v(" " + _vm._s(_vm.basicInformation.TotalFlow))]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "流速(m/s)" } },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.basicInformation.FlowVelocity)
                            ),
                          ]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "闸位(m)" } },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.basicInformation.GatePosition)
                            ),
                          ]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "水温" } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.basicInformation.WaterTemperature)
                            ),
                          ]
                        ),
                      ]
                    : _vm.basicInformation.DeviceTypeId == "HS_SWJ"
                    ? [
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "水位 (m)" } },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.basicInformation.WaterLevel_SWJ)
                            ),
                          ]
                        ),
                      ]
                    : [
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "水位 (m)" } },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.basicInformation.WaterLevel_LLJ)
                            ),
                          ]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "水深 (m)" } },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.basicInformation.WaterDepth)
                            ),
                          ]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "瞬时流量(m³/s)" } },
                          [_vm._v(" " + _vm._s(_vm.basicInformation.FlowRate))]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "累计流量(m³)" } },
                          [_vm._v(" " + _vm._s(_vm.basicInformation.TotalFlow))]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "流速(m/s)" } },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.basicInformation.FlowVelocity)
                            ),
                          ]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "水温" } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.basicInformation.WaterTemperature)
                            ),
                          ]
                        ),
                      ],
                  _c("el-descriptions-item", { attrs: { label: "是否报警" } }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.basicInformation.AlarmStatus == "1"
                            ? "报警"
                            : "不报警"
                        )
                    ),
                  ]),
                  _vm.basicInformation.DeviceTypeId == "HS_CKYTHZM"
                    ? [
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "闸位控制设定值" } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.basicInformation.GateControlSettingValue
                              )
                            ),
                          ]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "流量控制设定值" } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.basicInformation.FlowControlSettingValue
                              )
                            ),
                          ]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "电机数据A电压" } },
                          [_vm._v(_vm._s(_vm.basicInformation.MotorDataA_V))]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "电机数据B电压" } },
                          [_vm._v(_vm._s(_vm.basicInformation.MotorDataB_V))]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "电机数据C电压" } },
                          [_vm._v(_vm._s(_vm.basicInformation.MotorDataC_V))]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "电机数据A电流" } },
                          [_vm._v(_vm._s(_vm.basicInformation.MotorDataA_A))]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "电机数据B电流" } },
                          [_vm._v(_vm._s(_vm.basicInformation.MotorDataB_A))]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "电机数据C电流" } },
                          [_vm._v(_vm._s(_vm.basicInformation.MotorDataC_A))]
                        ),
                      ]
                    : _vm.basicInformation.DeviceTypeId == "HS_SWLLJ"
                    ? [
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "流量-水位1" } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.basicInformation.FlowlevelCorrespondence1
                              )
                            ),
                          ]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "流量-水位2" } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.basicInformation.FlowlevelCorrespondence2
                              )
                            ),
                          ]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "流量-水位3" } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.basicInformation.FlowlevelCorrespondence3
                              )
                            ),
                          ]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "流量-水位4" } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.basicInformation.FlowlevelCorrespondence4
                              )
                            ),
                          ]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "流量-水位5" } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.basicInformation.FlowlevelCorrespondence5
                              )
                            ),
                          ]
                        ),
                      ]
                    : _vm.basicInformation.DeviceTypeId == "HS_XSCSBMQLLJ" ||
                      _vm.basicInformation.DeviceTypeId == "HS_KFSCSBMQLLJ" ||
                      _vm.basicInformation.DeviceTypeId == "HS_WXSCSBMQLLJ"
                    ? [
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "水位传感器1状态" } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.getAlarmStatus(
                                  _vm.basicInformation.WaterLevelGaugesStatus_1
                                )
                              )
                            ),
                          ]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "水位传感器2状态" } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.getAlarmStatus(
                                  _vm.basicInformation.WaterLevelGaugesStatus_2
                                )
                              )
                            ),
                          ]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "水位传感器3状态" } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.getAlarmStatus(
                                  _vm.basicInformation.WaterLevelGaugesStatus_3
                                )
                              )
                            ),
                          ]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "水位传感器4状态" } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.getAlarmStatus(
                                  _vm.basicInformation.WaterLevelGaugesStatus_4
                                )
                              )
                            ),
                          ]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "换能器1状态" } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.getAlarmStatus(
                                  _vm.basicInformation.TransducersStatus_1
                                )
                              )
                            ),
                          ]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "换能器2状态" } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.getAlarmStatus(
                                  _vm.basicInformation.TransducersStatus_2
                                )
                              )
                            ),
                          ]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "换能器3状态" } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.getAlarmStatus(
                                  _vm.basicInformation.TransducersStatus_3
                                )
                              )
                            ),
                          ]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "换能器4状态" } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.getAlarmStatus(
                                  _vm.basicInformation.TransducersStatus_4
                                )
                              )
                            ),
                          ]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "换能器5状态" } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.getAlarmStatus(
                                  _vm.basicInformation.TransducersStatus_5
                                )
                              )
                            ),
                          ]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "换能器6状态" } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.getAlarmStatus(
                                  _vm.basicInformation.TransducersStatus_6
                                )
                              )
                            ),
                          ]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "换能器7状态" } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.getAlarmStatus(
                                  _vm.basicInformation.TransducersStatus_7
                                )
                              )
                            ),
                          ]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "换能器8状态" } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.getAlarmStatus(
                                  _vm.basicInformation.TransducersStatus_8
                                )
                              )
                            ),
                          ]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "换能器9状态" } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.getAlarmStatus(
                                  _vm.basicInformation.TransducersStatus_9
                                )
                              )
                            ),
                          ]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "换能器10状态" } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.getAlarmStatus(
                                  _vm.basicInformation.TransducersStatus_10
                                )
                              )
                            ),
                          ]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "换能器11状态" } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.getAlarmStatus(
                                  _vm.basicInformation.TransducersStatus_11
                                )
                              )
                            ),
                          ]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "换能器12状态" } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.getAlarmStatus(
                                  _vm.basicInformation.TransducersStatus_12
                                )
                              )
                            ),
                          ]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "换能器13状态" } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.getAlarmStatus(
                                  _vm.basicInformation.TransducersStatus_13
                                )
                              )
                            ),
                          ]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "换能器14状态" } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.getAlarmStatus(
                                  _vm.basicInformation.TransducersStatus_14
                                )
                              )
                            ),
                          ]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "换能器15状态" } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.getAlarmStatus(
                                  _vm.basicInformation.TransducersStatus_15
                                )
                              )
                            ),
                          ]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "换能器16状态" } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.getAlarmStatus(
                                  _vm.basicInformation.TransducersStatus_16
                                )
                              )
                            ),
                          ]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "换能器17状态" } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.getAlarmStatus(
                                  _vm.basicInformation.TransducersStatus_17
                                )
                              )
                            ),
                          ]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "换能器18状态" } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.getAlarmStatus(
                                  _vm.basicInformation.TransducersStatus_18
                                )
                              )
                            ),
                          ]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "换能器19状态" } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.getAlarmStatus(
                                  _vm.basicInformation.TransducersStatus_19
                                )
                              )
                            ),
                          ]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "换能器20状态" } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.getAlarmStatus(
                                  _vm.basicInformation.TransducersStatus_20
                                )
                              )
                            ),
                          ]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "换能器21状态" } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.getAlarmStatus(
                                  _vm.basicInformation.TransducersStatus_21
                                )
                              )
                            ),
                          ]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "换能器22状态" } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.getAlarmStatus(
                                  _vm.basicInformation.TransducersStatus_22
                                )
                              )
                            ),
                          ]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "换能器23状态" } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.getAlarmStatus(
                                  _vm.basicInformation.TransducersStatus_23
                                )
                              )
                            ),
                          ]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "换能器24状态" } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.getAlarmStatus(
                                  _vm.basicInformation.TransducersStatus_24
                                )
                              )
                            ),
                          ]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "换能器25状态" } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.getAlarmStatus(
                                  _vm.basicInformation.TransducersStatus_25
                                )
                              )
                            ),
                          ]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "换能器26状态" } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.getAlarmStatus(
                                  _vm.basicInformation.TransducersStatus_26
                                )
                              )
                            ),
                          ]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "换能器27状态" } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.getAlarmStatus(
                                  _vm.basicInformation.TransducersStatus_27
                                )
                              )
                            ),
                          ]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "换能器28状态" } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.getAlarmStatus(
                                  _vm.basicInformation.TransducersStatus_28
                                )
                              )
                            ),
                          ]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "换能器29状态" } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.getAlarmStatus(
                                  _vm.basicInformation.TransducersStatus_29
                                )
                              )
                            ),
                          ]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "换能器30状态" } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.getAlarmStatus(
                                  _vm.basicInformation.TransducersStatus_30
                                )
                              )
                            ),
                          ]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "换能器31状态" } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.getAlarmStatus(
                                  _vm.basicInformation.TransducersStatus_31
                                )
                              )
                            ),
                          ]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "换能器32状态" } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.getAlarmStatus(
                                  _vm.basicInformation.TransducersStatus_32
                                )
                              )
                            ),
                          ]
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
              _c("el-divider", { attrs: { "content-position": "left" } }, [
                _c("span", { staticStyle: { color: "#1a96d4" } }, [
                  _vm._v("基本信息"),
                ]),
              ]),
              _c(
                "el-descriptions",
                { staticClass: "left_descriptions", attrs: { column: 3 } },
                [
                  _c("el-descriptions-item", { attrs: { label: "设备编号" } }, [
                    _vm._v(_vm._s(_vm.basicInformation.DeviceNo)),
                  ]),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "软件版本号" } },
                    [_vm._v(_vm._s(_vm.basicInformation.VersionNo))]
                  ),
                  _c("el-descriptions-item", { attrs: { label: "安装地点" } }, [
                    _vm._v(_vm._s(_vm.basicInformation.InstalledLocation)),
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "安装时间" } }, [
                    _vm._v(_vm._s(_vm.basicInformation.InstalledTime)),
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "经度坐标" } }, [
                    _vm._v(_vm._s(_vm.basicInformation.Longitude)),
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "纬度坐标" } }, [
                    _vm._v(_vm._s(_vm.basicInformation.Latitude)),
                  ]),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "用水定额(m³)" } },
                    [_vm._v(_vm._s(_vm.basicInformation.WaterConsumptionNorm))]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "灌溉亩数(亩)" } },
                    [_vm._v(_vm._s(_vm.basicInformation.IrrigatedAcreage))]
                  ),
                  _c("el-descriptions-item", { attrs: { label: "作物类型" } }, [
                    _vm._v(_vm._s(_vm.basicInformation.CropTypeName)),
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "分支机构" } }, [
                    _vm._v(_vm._s(_vm.basicInformation.BelongAreaName)),
                  ]),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "设备安装图片" } },
                    [_vm._v(_vm._s(_vm.basicInformation.DeviceNo))]
                  ),
                  _c("el-descriptions-item", { attrs: { label: "设备描述" } }, [
                    _vm._v(_vm._s(_vm.basicInformation.DeviceRemark)),
                  ]),
                ],
                1
              ),
              _c("el-divider", { attrs: { "content-position": "left" } }, [
                _c("span", { staticStyle: { color: "#1a96d4" } }, [
                  _vm._v("个性化信息"),
                ]),
              ]),
              _c(
                "el-descriptions",
                { staticClass: "left_descriptions", attrs: { column: 3 } },
                [
                  _vm.basicInformation.DeviceTypeId == "HS_CKYTHZM"
                    ? [
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "闸板宽(mm)" } },
                          [_vm._v(_vm._s(_vm.basicInformation.GateWidth))]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "闸板高(mm)" } },
                          [_vm._v(_vm._s(_vm.basicInformation.GateHeight))]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "闸板最大行程(mm)" } },
                          [
                            _vm._v(
                              _vm._s(_vm.basicInformation.GateMaximumStroke)
                            ),
                          ]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "闸前设备" } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.dictionary(
                                    "HS_Device_GateFrontEquipment",
                                    _vm.basicInformation.GateFrontEquipmentId
                                  )
                                )
                            ),
                          ]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "闸后设备" } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.dictionary(
                                  "HS_Device_GateBackEquipment",
                                  _vm.basicInformation.GateBackEquipmentId
                                )
                              )
                            ),
                          ]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "供电方式" } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.dictionary(
                                  "HS_Device_PowerSupplyMode",
                                  _vm.basicInformation.PowerSupplyMode
                                )
                              )
                            ),
                          ]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "电机电压(V)" } },
                          [_vm._v(_vm._s(_vm.basicInformation.MotorVoltage))]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "电机功率(W)" } },
                          [_vm._v(_vm._s(_vm.basicInformation.MotorPower))]
                        ),
                      ]
                    : _vm._e(),
                  _vm.basicInformation.DeviceTypeId == "HS_XSCSBMQLLJ"
                    ? [
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "测箱宽h(mm)" } },
                          [
                            _vm._v(
                              _vm._s(_vm.basicInformation.MeasuringBoxWidth)
                            ),
                          ]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "测箱高w(mm)" } },
                          [
                            _vm._v(
                              _vm._s(_vm.basicInformation.MeasuringBoxHeight)
                            ),
                          ]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "换能器数量(n)" } },
                          [_vm._v(_vm._s(_vm.basicInformation.TransducerCount))]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "换能器频率" } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.dictionary(
                                    "HS_Device_TransducerFrequency",
                                    _vm.basicInformation.TransducerFrequency
                                  )
                                )
                            ),
                          ]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "供电方式" } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.dictionary(
                                  "HS_Device_PowerSupplyMode",
                                  _vm.basicInformation.PowerSupplyMode
                                )
                              )
                            ),
                          ]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "水位传感器安装方式" } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.dictionary(
                                  "HS_Device_WaterLevelSensorInstalledMethod",
                                  _vm.basicInformation
                                    .WaterLevelSensorInstalledMethod
                                )
                              )
                            ),
                          ]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "水位传感器数量" } },
                          [
                            _vm._v(
                              _vm._s(_vm.basicInformation.WaterLevelSensorCount)
                            ),
                          ]
                        ),
                      ]
                    : _vm._e(),
                  _vm.basicInformation.DeviceTypeId == "HS_KFSCSBMQLLJ"
                    ? [
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "渠道顶宽(mm)" } },
                          [_vm._v(_vm._s(_vm.basicInformation.ChannelTopWidth))]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "渠道底宽(mm)" } },
                          [
                            _vm._v(
                              _vm._s(_vm.basicInformation.ChannelBottomWidth)
                            ),
                          ]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "渠道深度(mm)" } },
                          [_vm._v(_vm._s(_vm.basicInformation.ChannelDeep))]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "声道数" } },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.basicInformation.ChannelCount)
                            ),
                          ]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "供电方式" } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.dictionary(
                                  "HS_Device_PowerSupplyMode",
                                  _vm.basicInformation.PowerSupplyMode
                                )
                              )
                            ),
                          ]
                        ),
                      ]
                    : _vm._e(),
                  _vm.basicInformation.DeviceTypeId == "HS_WXSCSBMQLLJ"
                    ? [
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "渠道顶宽(mm)" } },
                          [_vm._v(_vm._s(_vm.basicInformation.ChannelTopWidth))]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "渠道底宽(mm)" } },
                          [
                            _vm._v(
                              _vm._s(_vm.basicInformation.ChannelBottomWidth)
                            ),
                          ]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "渠道深度(mm)" } },
                          [_vm._v(_vm._s(_vm.basicInformation.ChannelDeep))]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "声道数" } },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.basicInformation.ChannelCount)
                            ),
                          ]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "供电方式" } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.dictionary(
                                  "HS_Device_PowerSupplyMode",
                                  _vm.basicInformation.PowerSupplyMode
                                )
                              )
                            ),
                          ]
                        ),
                      ]
                    : _vm._e(),
                  _vm.basicInformation.DeviceTypeId == "HS_BXECMQLLJ"
                    ? [
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "槽号" } },
                          [_vm._v(_vm._s(_vm.basicInformation.Slot))]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "B值(mm)" } },
                          [_vm._v(_vm._s(_vm.basicInformation.BValue))]
                        ),
                      ]
                    : _vm._e(),
                  _vm.basicInformation.DeviceTypeId == "HS_SWLLJ"
                    ? void 0
                    : _vm._e(),
                  _vm.basicInformation.DeviceTypeId == "HS_SWJ"
                    ? void 0
                    : _vm._e(),
                ],
                2
              ),
              _c("el-descriptions", {
                staticClass: "left_descriptions",
                attrs: { column: 3 },
              }),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.detailsDialog = false
                    },
                  },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.comLoading,
              expression: "comLoading",
            },
          ],
          key: _vm.dialogType,
          attrs: {
            title: "配置信息",
            visible: _vm.configurationDialog,
            width: "70%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.configurationDialog = $event
            },
          },
        },
        [
          _c(
            "el-row",
            {
              staticStyle: {
                display: "flex",
                "flex-wrap": "wrap",
                "justify-content": "left",
              },
            },
            [
              _c("el-col", { attrs: { span: 12 } }, [
                _c(
                  "div",
                  { staticStyle: { display: "flex", "margin-bottom": "15px" } },
                  [
                    _c(
                      "div",
                      { staticStyle: { width: "23%", "line-height": "28px" } },
                      [_vm._v(" 原密码 ：")]
                    ),
                    _c(
                      "div",
                      { staticStyle: { width: "50%" } },
                      [
                        _c("el-input", {
                          attrs: { type: "text", size: "mini", maxlength: 4 },
                          on: { input: _vm.handleInputPwd },
                          model: {
                            value: _vm.originalPassword,
                            callback: function ($$v) {
                              _vm.originalPassword = $$v
                            },
                            expression: "originalPassword",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
              _c(
                "el-col",
                {
                  staticStyle: { display: "flex", "margin-bottom": "15px" },
                  attrs: { span: 12 },
                },
                [
                  _c(
                    "div",
                    { staticStyle: { width: "23%", "line-height": "28px" } },
                    [_vm._v(" 在线状态 ：")]
                  ),
                  _vm.basicInformation.Status == 1
                    ? _c(
                        "el-tag",
                        {
                          staticStyle: { "margin-top": "5px" },
                          attrs: { type: "success", size: "mini" },
                        },
                        [_vm._v("在线")]
                      )
                    : _c(
                        "el-tag",
                        {
                          staticStyle: { "margin-top": "5px" },
                          attrs: { type: "info", size: "mini" },
                        },
                        [_vm._v("在装")]
                      ),
                ],
                1
              ),
              _vm._l(_vm.configuration, function (item, index) {
                return [
                  item.cmd == 101
                    ? [
                        _c(
                          "el-divider",
                          { key: index, attrs: { "content-position": "left" } },
                          [
                            _c("span", { staticStyle: { color: "#1a96d4" } }, [
                              _vm._v("基值上下限"),
                            ]),
                          ]
                        ),
                        _vm._l(item.value, function (ita1, ind1) {
                          return [
                            _vm.rowData.deviceTypeId == "HS_CKYTHZM"
                              ? [
                                  _vm._l(ita1, function (ita2, ind2) {
                                    return [
                                      _c("el-col", { attrs: { span: 8 } }, [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              display: "flex",
                                              "margin-bottom": "15px",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  width: "23%",
                                                  "line-height": "28px",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(ita2.label) +
                                                    " ："
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticStyle: { width: "50%" } },
                                              [
                                                ind1 == 0
                                                  ? [
                                                      _c("el-input-number", {
                                                        staticStyle: {
                                                          width: "100%",
                                                        },
                                                        attrs: {
                                                          size: "mini",
                                                          min: -7999.99,
                                                          max: 7999.99,
                                                        },
                                                        model: {
                                                          value: ita2.value,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              ita2,
                                                              "value",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "ita2.value",
                                                        },
                                                      }),
                                                    ]
                                                  : [
                                                      _c("el-input-number", {
                                                        staticStyle: {
                                                          width: "100%",
                                                        },
                                                        attrs: {
                                                          size: "mini",
                                                          min: 0,
                                                          max: 99.99,
                                                        },
                                                        model: {
                                                          value: ita2.value,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              ita2,
                                                              "value",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "ita2.value",
                                                        },
                                                      }),
                                                    ],
                                                _c("el-input-number", {
                                                  staticStyle: {
                                                    width: "100%",
                                                    "margin-top": "5px",
                                                  },
                                                  attrs: {
                                                    size: "mini",
                                                    disabled: "",
                                                  },
                                                  model: {
                                                    value: ita2.valueText,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        ita2,
                                                        "valueText",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "ita2.valueText",
                                                  },
                                                }),
                                              ],
                                              2
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  width: "10%",
                                                  "margin-left": "10px",
                                                },
                                              },
                                              [
                                                item.write == 1 &&
                                                ind1 == 2 &&
                                                ind2 == 2
                                                  ? _c(
                                                      "el-button",
                                                      {
                                                        attrs: {
                                                          size: "mini",
                                                          type: "success",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.writeCommand(
                                                              item
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("写入")]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  }),
                                ]
                              : _vm.rowData.deviceTypeId == "HS_SWJ"
                              ? [
                                  _vm._l(ita1, function (ita2, ind2) {
                                    return [
                                      _c("el-col", { attrs: { span: 12 } }, [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              display: "flex",
                                              "margin-bottom": "15px",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  width: "23%",
                                                  "line-height": "28px",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(ita2.label) +
                                                    " ："
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticStyle: { width: "50%" } },
                                              [
                                                ind1 == 0
                                                  ? [
                                                      _c("el-input-number", {
                                                        staticStyle: {
                                                          width: "100%",
                                                        },
                                                        attrs: {
                                                          size: "mini",
                                                          min: -7999.99,
                                                          max: 7999.99,
                                                        },
                                                        model: {
                                                          value: ita2.value,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              ita2,
                                                              "value",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "ita2.value",
                                                        },
                                                      }),
                                                    ]
                                                  : [
                                                      _c("el-input-number", {
                                                        staticStyle: {
                                                          width: "100%",
                                                        },
                                                        attrs: {
                                                          size: "mini",
                                                          min: 0,
                                                          max: 99.99,
                                                        },
                                                        model: {
                                                          value: ita2.value,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              ita2,
                                                              "value",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "ita2.value",
                                                        },
                                                      }),
                                                    ],
                                                _c("el-input-number", {
                                                  staticStyle: {
                                                    width: "100%",
                                                    "margin-top": "5px",
                                                  },
                                                  attrs: {
                                                    size: "mini",
                                                    disabled: "",
                                                  },
                                                  model: {
                                                    value: ita2.valueText,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        ita2,
                                                        "valueText",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "ita2.valueText",
                                                  },
                                                }),
                                              ],
                                              2
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  width: "10%",
                                                  "margin-left": "10px",
                                                },
                                              },
                                              [
                                                item.write == 1 &&
                                                ind1 == 2 &&
                                                ind2 == 0
                                                  ? _c(
                                                      "el-button",
                                                      {
                                                        attrs: {
                                                          size: "mini",
                                                          type: "success",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.writeCommand(
                                                              item
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("写入")]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c("el-col", {
                                        staticStyle: {
                                          border: "1px solid transparent",
                                        },
                                        attrs: { span: 1 },
                                      }),
                                    ]
                                  }),
                                ]
                              : [
                                  _vm._l(ita1, function (ita2, ind2) {
                                    return [
                                      _c("el-col", { attrs: { span: 12 } }, [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              display: "flex",
                                              "margin-bottom": "15px",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  width: "23%",
                                                  "line-height": "28px",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(ita2.label) +
                                                    " ："
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticStyle: { width: "50%" } },
                                              [
                                                ind1 == 0
                                                  ? [
                                                      _c("el-input-number", {
                                                        staticStyle: {
                                                          width: "100%",
                                                        },
                                                        attrs: {
                                                          size: "mini",
                                                          min: -7999.99,
                                                          max: 7999.99,
                                                        },
                                                        model: {
                                                          value: ita2.value,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              ita2,
                                                              "value",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "ita2.value",
                                                        },
                                                      }),
                                                    ]
                                                  : [
                                                      _c("el-input-number", {
                                                        staticStyle: {
                                                          width: "100%",
                                                        },
                                                        attrs: {
                                                          size: "mini",
                                                          min: 0,
                                                          max: 99.99,
                                                        },
                                                        model: {
                                                          value: ita2.value,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              ita2,
                                                              "value",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "ita2.value",
                                                        },
                                                      }),
                                                    ],
                                                _c("el-input-number", {
                                                  staticStyle: {
                                                    width: "100%",
                                                    "margin-top": "5px",
                                                  },
                                                  attrs: {
                                                    size: "mini",
                                                    disabled: "",
                                                  },
                                                  model: {
                                                    value: ita2.valueText,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        ita2,
                                                        "valueText",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "ita2.valueText",
                                                  },
                                                }),
                                              ],
                                              2
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  width: "10%",
                                                  "margin-left": "10px",
                                                },
                                              },
                                              [
                                                item.write == 1 &&
                                                ind1 == 2 &&
                                                ind2 == 1
                                                  ? _c(
                                                      "el-button",
                                                      {
                                                        attrs: {
                                                          size: "mini",
                                                          type: "success",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.writeCommand(
                                                              item
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("写入")]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  }),
                                ],
                          ]
                        }),
                      ]
                    : item.cmd == 162
                    ? [
                        _c(
                          "el-divider",
                          { key: index, attrs: { "content-position": "left" } },
                          [
                            _c("span", { staticStyle: { color: "#1a96d4" } }, [
                              _vm._v("主备信道中心站地址"),
                            ]),
                          ]
                        ),
                        _vm._l(item.value, function (ita2, ind2) {
                          return [
                            _c("el-col", { attrs: { span: 12 } }, [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "margin-bottom": "15px",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        width: "23%",
                                        "line-height": "28px",
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(ita2.label) + "：")]
                                  ),
                                  _c(
                                    "div",
                                    { staticStyle: { width: "50%" } },
                                    [
                                      _c("el-input", {
                                        attrs: { size: "mini" },
                                        model: {
                                          value: ita2.value,
                                          callback: function ($$v) {
                                            _vm.$set(ita2, "value", $$v)
                                          },
                                          expression: "ita2.value",
                                        },
                                      }),
                                      _c("el-input", {
                                        staticStyle: { "margin-top": "5px" },
                                        attrs: { size: "mini", disabled: "" },
                                        model: {
                                          value: ita2.valueText,
                                          callback: function ($$v) {
                                            _vm.$set(ita2, "valueText", $$v)
                                          },
                                          expression: "ita2.valueText",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        width: "10%",
                                        "margin-left": "10px",
                                      },
                                    },
                                    [
                                      item.write == 1 && ind2 == 1
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                size: "mini",
                                                type: "success",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.writeCommand(item)
                                                },
                                              },
                                            },
                                            [_vm._v("写入")]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        }),
                      ]
                    : item.cmd == 165
                    ? [
                        _c(
                          "el-divider",
                          { key: index, attrs: { "content-position": "left" } },
                          [
                            _c("span", { staticStyle: { color: "#1a96d4" } }, [
                              _vm._v("水位流量关系"),
                            ]),
                          ]
                        ),
                        _vm._l(item.value, function (ita3, ind3) {
                          return [
                            _c("el-col", { attrs: { span: 12 } }, [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "margin-bottom": "15px",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        width: "23%",
                                        "line-height": "28px",
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(ita3.label) + "：")]
                                  ),
                                  _c(
                                    "div",
                                    { staticStyle: { width: "50%" } },
                                    [
                                      _c("el-input-number", {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          size: "mini",
                                          min: 0,
                                          max: 999.999,
                                        },
                                        model: {
                                          value: ita3.value,
                                          callback: function ($$v) {
                                            _vm.$set(ita3, "value", $$v)
                                          },
                                          expression: "ita3.value",
                                        },
                                      }),
                                      _c("el-input-number", {
                                        staticStyle: {
                                          width: "100%",
                                          "margin-top": "5px",
                                        },
                                        attrs: { size: "mini", disabled: "" },
                                        model: {
                                          value: ita3.valueText,
                                          callback: function ($$v) {
                                            _vm.$set(ita3, "valueText", $$v)
                                          },
                                          expression: "ita3.valueText",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        width: "10%",
                                        "margin-left": "10px",
                                      },
                                    },
                                    [
                                      item.write == 1 && ind3 == 9
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                size: "mini",
                                                type: "success",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.writeCommand(item)
                                                },
                                              },
                                            },
                                            [_vm._v("写入")]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        }),
                      ]
                    : [
                        _c("el-col", { key: index, attrs: { span: 12 } }, [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "margin-bottom": "15px",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    width: "23%",
                                    "line-height": "28px",
                                  },
                                },
                                [
                                  _vm._v(" " + _vm._s(item.label) + " ："),
                                  item.cmd == 127
                                    ? _c(
                                        "div",
                                        {
                                          staticStyle: { "margin-top": "5px" },
                                        },
                                        [_vm._v(" 累计流量 ： ")]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                              _c(
                                "div",
                                { staticStyle: { width: "50%" } },
                                [
                                  item.cmd == 150
                                    ? [
                                        _c("el-input", {
                                          attrs: {
                                            type: "text",
                                            size: "mini",
                                            maxlength: 4,
                                          },
                                          on: { input: _vm.handleInputPwds },
                                          model: {
                                            value: item.value,
                                            callback: function ($$v) {
                                              _vm.$set(item, "value", $$v)
                                            },
                                            expression: "item.value",
                                          },
                                        }),
                                        _c("el-input", {
                                          staticStyle: {
                                            width: "100%",
                                            "margin-top": "5px",
                                          },
                                          attrs: {
                                            type: "text",
                                            size: "mini",
                                            disabled: "",
                                          },
                                          model: {
                                            value: item.valueText,
                                            callback: function ($$v) {
                                              _vm.$set(item, "valueText", $$v)
                                            },
                                            expression: "item.valueText",
                                          },
                                        }),
                                      ]
                                    : item.cmd == 127
                                    ? [
                                        _c("el-input-number", {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            size: "mini",
                                            min: 0,
                                            max: 7999999999,
                                          },
                                          model: {
                                            value: item.value,
                                            callback: function ($$v) {
                                              _vm.$set(item, "value", $$v)
                                            },
                                            expression: "item.value",
                                          },
                                        }),
                                        _c("el-input-number", {
                                          staticStyle: {
                                            width: "100%",
                                            "margin-top": "5px",
                                          },
                                          attrs: { size: "mini", disabled: "" },
                                          model: {
                                            value: item.valueText,
                                            callback: function ($$v) {
                                              _vm.$set(item, "valueText", $$v)
                                            },
                                            expression: "item.valueText",
                                          },
                                        }),
                                      ]
                                    : item.cmd == 131
                                    ? [
                                        _c("el-input-number", {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            size: "mini",
                                            min: -999999.999,
                                            max: 999999.999,
                                          },
                                          model: {
                                            value: item.value,
                                            callback: function ($$v) {
                                              _vm.$set(item, "value", $$v)
                                            },
                                            expression: "item.value",
                                          },
                                        }),
                                        _c("el-input-number", {
                                          staticStyle: {
                                            width: "100%",
                                            "margin-top": "5px",
                                          },
                                          attrs: { size: "mini", disabled: "" },
                                          model: {
                                            value: item.valueText,
                                            callback: function ($$v) {
                                              _vm.$set(item, "valueText", $$v)
                                            },
                                            expression: "item.valueText",
                                          },
                                        }),
                                      ]
                                    : item.cmd == 163
                                    ? [
                                        _c("el-input-number", {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            size: "mini",
                                            min: 0,
                                            max: 9.999,
                                          },
                                          model: {
                                            value: item.value,
                                            callback: function ($$v) {
                                              _vm.$set(item, "value", $$v)
                                            },
                                            expression: "item.value",
                                          },
                                        }),
                                        _c("el-input-number", {
                                          staticStyle: {
                                            width: "100%",
                                            "margin-top": "5px",
                                          },
                                          attrs: { size: "mini", disabled: "" },
                                          model: {
                                            value: item.valueText,
                                            callback: function ($$v) {
                                              _vm.$set(item, "valueText", $$v)
                                            },
                                            expression: "item.valueText",
                                          },
                                        }),
                                      ]
                                    : item.cmd == 164
                                    ? [
                                        _c("el-input-number", {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            size: "mini",
                                            min: -99.999,
                                            max: 99.999,
                                          },
                                          model: {
                                            value: item.value,
                                            callback: function ($$v) {
                                              _vm.$set(item, "value", $$v)
                                            },
                                            expression: "item.value",
                                          },
                                        }),
                                        _c("el-input-number", {
                                          staticStyle: {
                                            width: "100%",
                                            "margin-top": "5px",
                                          },
                                          attrs: { size: "mini", disabled: "" },
                                          model: {
                                            value: item.valueText,
                                            callback: function ($$v) {
                                              _vm.$set(item, "valueText", $$v)
                                            },
                                            expression: "item.valueText",
                                          },
                                        }),
                                      ]
                                    : [
                                        _c("el-input-number", {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            size: "mini",
                                            min: -99999999999,
                                            max: 99999999999,
                                          },
                                          model: {
                                            value: item.value,
                                            callback: function ($$v) {
                                              _vm.$set(item, "value", $$v)
                                            },
                                            expression: "item.value",
                                          },
                                        }),
                                        _c("el-input-number", {
                                          staticStyle: {
                                            width: "100%",
                                            "margin-top": "5px",
                                          },
                                          attrs: { size: "mini", disabled: "" },
                                          model: {
                                            value: item.valueText,
                                            callback: function ($$v) {
                                              _vm.$set(item, "valueText", $$v)
                                            },
                                            expression: "item.valueText",
                                          },
                                        }),
                                      ],
                                ],
                                2
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    width: "10%",
                                    "margin-left": "10px",
                                  },
                                },
                                [
                                  item.write == 1
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            size: "mini",
                                            type: "success",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.writeCommand(item)
                                            },
                                          },
                                        },
                                        [_vm._v("写入")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]),
                      ],
                ]
              }),
            ],
            2
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "success", plain: "" },
                  on: {
                    click: function ($event) {
                      return _vm.refreshData()
                    },
                  },
                },
                [_vm._v("刷新")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.configurationDialog = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.hisLoading,
              expression: "hisLoading",
            },
          ],
          attrs: {
            title: "监测站固态存储数据",
            visible: _vm.jczDataDialog,
            width: "80%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.jczDataDialog = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.listQuery, inline: true } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      size: "mini",
                      format: "yyyy-MM-dd HH",
                      type: "datetimerange",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                    },
                    model: {
                      value: _vm.listQuery.timer,
                      callback: function ($$v) {
                        _vm.$set(_vm.listQuery, "timer", $$v)
                      },
                      expression: "listQuery.timer",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        size: "mini",
                        icon: "el-icon-upload2",
                      },
                      on: { click: _vm.getHistoryDatas },
                    },
                    [_vm._v("发送查看指令")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        size: "mini",
                        icon: "el-icon-search",
                      },
                      on: { click: _vm.getHistoryData },
                    },
                    [_vm._v("接收设备数据")]
                  ),
                  _c("br"),
                  _c(
                    "span",
                    { staticStyle: { "font-size": "12px", color: "red" } },
                    [
                      _c("span", { staticStyle: { color: "red" } }),
                      _vm._v(
                        "Tips：发送查看指令后请点击“接收设备数据”按钮查看刷新信息，预计5分钟接收完全部数据信息。"
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.jczTableData,
                border: "",
                align: "center",
                height: "500px",
              },
            },
            [
              _c("el-table-column", { attrs: { type: "index", width: "50" } }),
              _c("el-table-column", {
                attrs: {
                  label: "设备编号",
                  "show-overflow-tooltip": "",
                  "min-width": "180px",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.deviceNo))])]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "上报时间",
                  "show-overflow-tooltip": "",
                  "min-width": "180px",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.last_active))]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "采集时间",
                  "show-overflow-tooltip": "",
                  "min-width": "180px",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.time))])]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "流量计水位",
                  "show-overflow-tooltip": "",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.waterLevel_LLJ))]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "流量计水深",
                  "show-overflow-tooltip": "",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.waterDepth))]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "水位计水位",
                  "show-overflow-tooltip": "",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.WaterLevel_SWJ))]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "累计流量",
                  "show-overflow-tooltip": "",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.totalFlow))])]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "流速",
                  "show-overflow-tooltip": "",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.flowVelocity))]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "闸位",
                  "show-overflow-tooltip": "",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.gatePosition))]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "水温",
                  "show-overflow-tooltip": "",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(scope.row.waterTemperature)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  "show-overflow-tooltip": "",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.transferHistory(scope.row)
                              },
                            },
                          },
                          [_vm._v("转存历史")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("pagination", {
            attrs: {
              total: _vm.total,
              page: _vm.listQuery.page,
              limit: _vm.listQuery.limit,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.listQuery, "page", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.listQuery, "limit", $event)
              },
              pagination: _vm.handleCurrentChanges,
            },
          }),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.jczDataDialog = false
                    },
                  },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }